import React from 'react';
import './CardComponent.css';
import QRCode from "react-qr-code";
const CardComponent = ({students}) => {
    return (
        <div className="cards-container">
            {
                students.map((item, index)=>{
                    return(
                        <div className={`cards col-md-6 ${(index + 1) % 4 === 0 && index !== students.length - 1 ? 'break-page' : ''}`}>
                            <div className="cards-content">
                                <div className="student-data">
                                    <QRCode
                                        size={90}
                                        value={item.StudentID}
                                        viewBox={`0 0 256 256`}
                                    />
                                </div>
                                <div className="text-data" style={{ fontSize: '16px', marginTop: '2px', color: '#000' }}>
                                    <b>{item.StudentID}</b><br/>
                                    <div style={{lineHeight: '16px', fontSize: '18px', marginTop: '2px', fontWeight: '400'}}><b>{item.CourseName}</b></div>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    );
};

export default CardComponent;
