import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import Loader from "../common/loader";
import PageTitle from "../common/pagetitle/pagetitle";
import { showAlert } from "../common/sweetalert/sweetalert";
import { serverLink, encryptData } from "../url";
import {formatDate, formatDateAndTime} from "../resources/constants";


const InternshipSettings = (props) => {
    const [isloading, setIsLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    const [formData, setFormData] = useState({
        EntryID: "",
        Title: "",
        StartDate: "",
        EndDate: "",
        Status: "",
        InsertedBy: props.loginData[0].EmailAddress,
    })
    const getData = async () => {
        await axios.get(`${serverLink}foundation/dashboard/internship/settings`).then((res) => {
            if (res.data.length > 0) {
                setData(res.data)
            }
        })
        setIsLoading(false)
    }

    useEffect(() => {
        getData();
    }, [])

    const addSettings = async (e) => {
        e.preventDefault();
        if (formData.Title.toString().trim() === "") {
            toast.error("Please enter the internship title");
            return false;
        }
        if (formData.StartDate.toString().trim() === "") {
            toast.error("Please select internship start date");
            return false;
        }
        if (formData.EndDate.toString().trim() === "") {
            toast.error("Please select internship start date");
            return false;
        }
        if (formData.Status.toString().trim() === "") {
            toast.error("Please select internship status");
            return false;
        }
        setLoading(true);
        const sendData =   {
          ...formData,
            StartDate: formatDate(formData.StartDate),
            EndDate: formatDate(formData.EndDate),
        }
        try {
            if (formData.EntryID === "") {
                await axios.post(`${serverLink}foundation/dashboard/internship/settings/add`, sendData).then((res) => {
                    if (res.data.message === "success") {
                        setLoading(false);
                        getData();
                        Reset();
                        showAlert("success", "Record added successfully", "success");
                        document.getElementById("btn_close").click();
                    } else if (res.data.message === "exist") {
                        setLoading(false)
                        showAlert("warning", "Internship with this title already exist", "warning")
                    } else {
                        showAlert("error", "Network error, please check your connection", "error")
                    }
                })
            }
        } catch (e) {
            console.log(e)
            setLoading(false)
            showAlert("error", "Network Error, try again", "error");
        }

    }

    const onEdit = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value,
        })
    }

    const Reset = () => {
        setFormData({
            ...formData,
            EntryID: "",
            Title: "",
            StartDate: "",
            EndDate: "",
            Status: "",
        })
    }

    return isloading === true ?
        (<Loader />) : (
            <>
                <PageTitle title={["Internship", "Internship Settings"]} />
                <section className="section">
                    <div className="row align-items-top">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="d-flex justify-content-start">
                                        <h4>Internship Settings</h4>
                                    </div>
                                    <div
                                        className="d-flex justify-content-end"
                                        data-kt-customer-table-toolbar="base"
                                    >
                                        <button data-bs-toggle="modal" data-bs-target="#basicModal"
                                                className="btn btn-sm btn-primary text-white"
                                                onClick={() => {
                                                    Reset()
                                                }}
                                        >
                                            <i className="fa fa-plus" /> Add Internship Settings
                                        </button>
                                    </div>
                                </div>

                                <div className="card-body">
                                    <div className="table table-responsive-lg">
                                        <table className="table table-striped">
                                            <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Title</th>
                                                <th scope="col">Start Date</th>
                                                <th scope="col">End Date</th>
                                                <th scope="col">Status</th>
                                                <th scope="col"> Date Added</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                data.length > 0 ?
                                                    data.map((x, i) => {
                                                        return (
                                                            <tr key={i}>
                                                                <td>{i + 1}</td>
                                                                <td style={{ maxWidth: "450px" }}>{x.Title}</td>
                                                                <td>{formatDateAndTime(x.StartDate, 'date')}</td>
                                                                <td>{formatDateAndTime(x.EndDate, 'date')}</td>
                                                                <td>{x.Status}</td>
                                                                <td>{formatDateAndTime(x.InsertedDate, 'date')}</td>
                                                            </tr>
                                                        )
                                                    })
                                                    :
                                                    <tr>
                                                        <td colSpan={7}>No Record</td>
                                                    </tr>
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="modal fade" id="basicModal" tabIndex={-1} aria-hidden="true" style={{ display: 'none' }}>
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content modal-lg">
                            <div className="modal-header">
                                <h5 className="modal-title">Internship Settings</h5>
                                <button type="button" id="btn_close" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                            </div>
                            <div className="modal-body">
                                <form onSubmit={addSettings}>
                                    <div className="row">

                                        <div className="col-lg-12">
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <label htmlFor="Title" className="col-md-12 col-lg-12 col-form-label">Title</label>
                                                    <input type="text" className="form-control" id="Title" value={formData.Title}  onChange={onEdit} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <label htmlFor="StartDate" className="col-md-12 col-lg-12 col-form-label">Start Date</label>
                                                    <input type="date" className="form-control" id="StartDate" value={formData.StartDate} onChange={onEdit} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <label htmlFor="EndDate" className="col-md-12 col-lg-12 col-form-label">End Date</label>
                                                    <input type="date" className="form-control" id="EndDate" value={formData.EndDate} onChange={onEdit} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <label htmlFor="Status" className="col-md-12 col-lg-12 col-form-label">Status</label>
                                                    <select className="form-control form-select" onChange={onEdit} id="Status" value={formData.Status}>
                                                        <option value={""}>-select Status-</option>
                                                        <option value="active">Active</option>
                                                        <option value="inactive">InActive</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <div className="row mb-3">
                                                <div className="col-md-12 text-center">
                                                    <button className="btn btn-lg btn-primary col-md-6" type="submit" disabled={loading ? true : false}>
                                                        {
                                                            loading === true &&
                                                            <>
                                                                <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true" />&emsp;
                                                            </>
                                                        }
                                                        {loading === true ? "Loading..." :
                                                            "Submit"}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
}
const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};

export default connect(mapStateToProps, null)(InternshipSettings);
