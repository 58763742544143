import React from 'react'
import Loader from "../common/loader";
import PageTitle from '../common/pagetitle/pagetitle';
import axios from 'axios';
import { useEffect } from 'react';
import {moneyFormat, serverLink} from '../url';
import { useState } from 'react';
import ReportTable from "../common/table/report_table";
import { toast } from "react-toastify";
import { connect } from 'react-redux';
import {showAlert, showConfirm} from '../common/sweetalert/sweetalert';
import JoditEditor from "jodit-react";
import { ProgressBar } from "react-bootstrap";

const ResultNotification = (props) => {
    const editorBio = React.createRef();
    const [isloading, setIsLoading] = useState(true);
    const [IsFormLoading, setIsFormLoading] = useState(false)
    const [totalRecord, setTotalRecord] = useState(0)
    const [recordCount, setRecordCount] = useState(10000)
    const [selectedID, setSelectedID] = useState([])
    const [selectedEmail, setSelectedEmail] = useState([])
    const [selectedName, setSelectedName] = useState([])
    const [anatomy, setAnatomy] = useState([])
    const [biochemistry, setBiochemistry] = useState([])
    const [physiology, setPhysiology] = useState([])
    const [status, setStatus] = useState([])
    const [semester, setSemester] = useState([])
    const column = [" ", "S/N",  "Student Name", " Student ID", "Email Address", "Anatomy", "Biochemistry", "Physiology", "Status", "Semester", "IsNotified"];
    const [data, setData] = useState([]);
    const [tableHeight, setTableHeight] = useState("600px");
    const [formData, setFormData] = useState({
        msgTitle: "",
        msgSubject: "",
        msgTo: "",
        msgBody: "",
        Cohort: "",
        RejectReason:"",
        InsertedBy: props.loginData[0].EmailAddress,
        EntryID:""
    })

    const [progress, setProgress] = useState({
        percentage: 0,
        variant: 'danger'
    });

    const getData = async () => {
        await axios.get(`${serverLink}foundation/dashboard/students/all`)
            .then(res => {
                console.log("data", data)
                if (res.data.message === "success") {

                    if (res.data.allRecords.length > 0){
                        setTotalRecord(res.data.allRecords[0].allRecords)
                    }
                    const result = res.data.data.slice(0, recordCount);
                    let rows = [];
                    result.map((applicant, index) => {
                        rows.push([
                            <td className="text-xs font-weight-bold">
                                <input type="checkbox"
                                       id="checkItem"
                                       data_StudentName={applicant.StudentName}
                                       data_StudentID={applicant.StudentID}
                                       data_EmailAddress={applicant.EmailAddress}
                                       data_Anatomy={applicant.Anatomy}
                                       data_Biochemistry={applicant.Biochemistry}
                                       data_Physiology={applicant.Physiology}
                                       data_Status={applicant.Status}
                                       data_Semester={applicant.Semester}
                                       data_IsNotified={applicant.IsNotified}
                                       data={JSON.stringify(applicant)}
                                       className="checkItem"
                                       name="checkItem"
                                       value={applicant.EntryID}
                                       onChange={onCheck} />
                            </td>,
                            index + 1,
                            applicant.StudentName,
                            applicant.StudentID,
                            applicant.EmailAddress,
                            applicant.Anatomy,
                            applicant.Biochemistry,
                            applicant.Physiology,
                            applicant.Status,
                            applicant.Semester,
                            applicant.IsNotified,
                        ]);
                    });
                    setTableHeight(result.length > 100 ? "700px" : "600px");
                    setData(rows);
                    setIsLoading(false)
                } else {
                    toast.error("Data not available");
                }
                setIsLoading(false)
            })
            .catch(e => {
                console.log(e)
            })
    }

    const unCheckAll = async () => {
        let checkBox = await document.getElementsByClassName('checkItem');
        for (let i = 0; i < checkBox.length ; i++) {
            if (checkBox[i].checked) {
                checkBox[i].checked = false;
            }
        }
    }

    const checkAll = async () => {
        let checkBox = await document.getElementsByClassName('checkItem');

        for (let i = 0; i < checkBox.length ; i++) {
            let studentName = checkBox[i].getAttribute("data_StudentName");
            let studentID = checkBox[i].getAttribute("data_StudentID");
            let applicantEmail = checkBox[i].getAttribute("data_EmailAddress");
            let applicantAnatomy = checkBox[i].getAttribute("data_Anatomy");
            let applicantBiochemistry = checkBox[i].getAttribute("data_Biochemistry");
            let applicantPhysiology = checkBox[i].getAttribute("data_Physiology");
            let applicantStatus = checkBox[i].getAttribute("data_Status");
            let applicantSemester = checkBox[i].getAttribute("data_Semester");


            if (!checkBox[i].checked) {
                checkBox[i].checked = true;
                setSelectedID(prevState => [...prevState, studentID])
                setSelectedEmail(prevState => [...prevState, applicantEmail])
                setSelectedName(prevState => [...prevState, studentName])
                setAnatomy(prevState => [...prevState, applicantAnatomy])
                setBiochemistry(prevState => [...prevState, applicantBiochemistry])
                setPhysiology(prevState => [...prevState, applicantPhysiology])
                setStatus(prevState => [...prevState, applicantStatus])
                setSemester(prevState => [...prevState, applicantSemester])
            }else{
                checkBox[i].checked = false;
                setSelectedID([])
                setSelectedEmail([])
                setSelectedName([])
            }
        }
    }

    const onCheck = (e) => {
        let studentEmailAddress_ = e.target.getAttribute("data_EmailAddress");
        let studentID_ = e.target.getAttribute("data_StudentID");
        let studentName_ = e.target.getAttribute("data_StudentName");
        let anatomy_ = e.target.getAttribute("data_Anatomy");
        let biochemistry_ = e.target.getAttribute("data_Biochemistry");
        let physiology_ = e.target.getAttribute("data_Physiology");
        let status_ = e.target.getAttribute("data_Status");
        let semester_ = e.target.getAttribute("data_Semester");

        if (e.target.checked) {
            setSelectedID(prevState => [...prevState, studentID_]);
            setSelectedEmail(prevState => [...prevState, studentEmailAddress_]);
            setSelectedName(prevState => [...prevState, studentName_]);
            setAnatomy(prevState => [...prevState, anatomy_])
            setBiochemistry(prevState => [...prevState, biochemistry_])
            setPhysiology(prevState => [...prevState, physiology_])
            setStatus(prevState => [...prevState, status_])
            setSemester(prevState => [...prevState, semester_])

        } else {
            let newSelectedID = selectedID.filter(e => e.toString() !== studentID_.toString());
            let newSelectedEmail = selectedEmail.filter(e => e.toString() !== studentEmailAddress_.toString());
            let newSelectedName = selectedName.filter(e => e.toString() !== studentName_.toString());
            let newAnatomy = anatomy.filter(e => e.toString() !== anatomy_.toString());
            let newBiochemistry = biochemistry.filter(e => e.toString() !== biochemistry_.toString());
            let newPhysiology = physiology.filter(e => e.toString() !== physiology_.toString());
            let newStatus = status.filter(e => e.toString() !== status_.toString());
            let newSemester = semester.filter(e => e.toString() !== semester_.toString());


            setSelectedID(newSelectedID);
            setSelectedEmail(newSelectedEmail);
            setSelectedName(newSelectedName);
            setAnatomy(newAnatomy);
            setBiochemistry(newBiochemistry);
            setPhysiology(newPhysiology);
            setStatus(newStatus);
            setSemester(newSemester);
        }
        console.log(selectedID);
    };


    useEffect(() => {
        getData().then(r => {});
    }, [recordCount])

    const onEdit = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value,
        })
    }

    const sendValidationLink = async () => {
        if (selectedID.length < 1) {
            await showAlert("EMPTY FIELD", "Please select record", "error");
            return false;
        }

        setIsFormLoading(true);

        selectedID.map(async (item, index)=> {
            const sendEmailData = {
                from: 'do-not-reply@bazeuniversity.edu.ng',
                name: selectedName[index],
                studentID: selectedID[index],
                anatomy: anatomy[index],
                biochemistry: biochemistry[index],
                physiology: physiology[index],
                status: status[index],
                semester: semester[index],
                to: selectedEmail[index],
                // to: 'aikinyiltd@gmail.com',
            }
            await axios.post(`${serverLink}foundation/dashboard/students/result_notification`, sendEmailData).then(r => {
                toast.success("Sent successfully");
            })

            const percentage = (index + 1) / selectedID.length * 100;
            let variant = "";
            if (percentage <= 25)
                variant = 'danger'
            else if (percentage > 25 && percentage <= 50)
                variant = 'warning'
            else if (percentage > 50 && percentage <= 75)
                variant = 'info'
            else
                variant = 'success'

            setProgress({
                ...progress,
                percentage: Math.round(percentage),
                variant: variant
            })

            if (index + 1 === selectedID.length) {
                toast.info("Process completed successfully");
                setIsFormLoading(false);
                setFormData({
                    ...formData,
                    msgTitle: "",
                    msgSubject: "",
                    msgTo: "",
                    msgBody: ""
                })
                setSelectedID([])
                setSelectedEmail([])
                setSelectedName([])
                await unCheckAll()
                setTimeout(()=>{
                    window.location.reload();
                }, 1000)
            }
        })
    }

    return isloading === true ?
        (<Loader />) : (
            <>
                <PageTitle title={["Administrator", "Notify Students"]} />
                <div
                    className="d-flex justify-content-between mb-2"
                    data-kt-customer-table-toolbar="base"
                >
                    <div className="form-check form-switch">
                        <input className="form-check-input checkAll" type="checkbox" role="switch" onChange={()=>checkAll()} id="checkAll"/>
                        <label className="form-check-label" htmlFor="checkAll">Select all</label>
                    </div>
                    <div>
                        <select id="count" value={recordCount} onChange={(event)=>setRecordCount(event.target.value)}>
                            <option value="500">500</option>
                            <option value="1000">1000</option>
                        </select>
                        <label className="form-check-label" htmlFor="count"> &nbsp; of {moneyFormat(totalRecord)} </label>
                    </div>
                    {
                        selectedID.length > 0 ?
                            IsFormLoading ?
                                <button type="button" className="btn btn-sm btn-primary text-white mb-4"  >Please wait...</button> :
                                <button className="btn btn-sm btn-primary text-white mb-4"
                                        onClick={() => {
                                            showConfirm(
                                                "Warning",
                                                `Are you sure you want to start this process?`,
                                                "warning"
                                            ).then(async (confirm) => {
                                                if (confirm) {
                                                    await sendValidationLink();
                                                }
                                            })
                                        }}
                                >
                                    <i className="fa fa-check" /> Send
                                </button>
                            :
                            <div> </div>
                    }
                </div>
                <div className='mb-1'>
                    <ProgressBar now={progress.percentage} label={`${progress.percentage}%`} variant={progress.variant} striped />
                </div>
                <ReportTable title={``} columns={column} data={data} height={tableHeight}/>

            </>
        )
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
        applicantData: state.ApplicantDetails
    };
};

export default connect(mapStateToProps, null)(ResultNotification);
