import React from 'react'
import Loader from "../common/loader";
import PageTitle from '../common/pagetitle/pagetitle';
import axios from 'axios';
import { useEffect } from 'react';
import { serverLink } from '../url';
import { useState } from 'react';
import ReportTable from "../common/table/report_table";
import { toast } from "react-toastify";
import { connect } from 'react-redux';
import { showAlert } from '../common/sweetalert/sweetalert';

const ChangeOfProgramme = (props) => {
    const editorBio = React.createRef();
    const [isloading, setIsLoading] = useState(true);
    const column = ["S/N", "APP ID", "FULL Name", "EMAIL ADDRESS",  "Old Course Name", "New Course Name", "Highest Qualification", "Action"];
    const [data, setData] = useState([]);
    const [tableHeight, setTableHeight] = useState("600px");
    const [formData, setFormData] = useState({
        CourseID: "",
        AppID:"",
        InsertedBy: props.loginData[0].EmailAddress,
    })

    const getData = async () => {
        await axios.get(`${serverLink}foundation/login/change-programme-list`).then((res) => {
            const result = res.data;
            if (result.length > 0) {
                let rows = [];
                result.map((applicant, index) => {
                    rows.push([
                        index + 1,
                        applicant.ApplicationID,
                        applicant.FirstName + " " + applicant.MiddleName + " " + applicant.Surname,
                        applicant.EmailAddress,
                        applicant.CourseName,
                        applicant.CourseName2,
                        applicant.HighestQualification,
                        <>
                        <button className="btn btn-sm btn-success text-white" id="btnApprove"
                                onClick={() => {approve(applicant)
                                }}
                            >
                                <i className="bi bi-check-circle" />
                            </button>,
                            <button className="btn btn-sm btn-danger text-white" id="btnReject"
                            onClick={() => {reject(applicant)
                            }}
                        >
                            <i className="bi bi-x-circle-fill" />
                        </button>
                        </>
                    ]);
                });
                setTableHeight(result.length > 100 ? "1000px" : "600px");
                setData(rows);
            } else {
                // toast.error("There are no rejected application available");
            }
            setIsLoading(false);
        })
            .catch((err) => {
                toast.error("NETWORK ERROR");
            });
    }

    useEffect(() => {
        getData();
    }, [])

    const Reset = () => {
        setFormData({
            ...formData,
            FullName: "",
            Cohort: "",
            RejectReason:"",
            AreaOfTraining:""
        })
    }
    const approve = async (data) => {
        toast.info('Please Wait...');
        setIsLoading(true);
        try {
            await axios.post(`${serverLink}foundation/dashboard/approve/change/programme`, data).then(async (res) => {
                if (res.data.message === "success") {
                    toast.success('Change of programme approved successfully')
                    getData();
                    setIsLoading(false);
                    setTimeout(() => {
                        window.location.href = 'change-of-programme';
                    }, 2000)
                    const sendEmailData = {
                        from: 'ideas@bazeuniversity.edu.ng',
                        to: data.EmailAddress,
                        name:data.FirstName + " " + data.MiddleName + " " + data.Surname,
                        course: data.CourseName2,
                    }
                    await axios.post(`${serverLink}foundation/dashboard/send_email/approve/change/programme`, sendEmailData).then(r => {
                    })
                } else {
                    showAlert("error", "Network error, please check your connection", "error")
                }
            })
        } catch (e) {
            console.log(e)
            setIsLoading(false)
            showAlert("error", "Network Error, try again", "error");
        }

    }

    const reject = async (data) => {
        toast.info('Please Wait...');
        setIsLoading(true);
        try {
            await axios.post(`${serverLink}foundation/dashboard/reject/change/programme`, data).then(async (res) => {
                if (res.data.message === "success") {
                    toast.success('Change of programme rejected')
                    getData();
                    Reset();
                    setIsLoading(false);
                    setTimeout(() => {
                        window.location.href = 'change-of-programme';
                    }, 2000)
                    const sendEmailData = {
                        from: 'ideas@bazeuniversity.edu.ng',
                        to: data.EmailAddress,
                        name:data.FirstName + " " + data.MiddleName + " " + data.Surname,
                        course: data.CourseName2,
                    }
                    await axios.post(`${serverLink}foundation/dashboard/send_email/reject/change/programme`, sendEmailData).then(r => {
                    })
                } else {
                    showAlert("error", "Network error, please check your connection", "error")
                }
            })
        } catch (e) {
            console.log(e)
            setIsLoading(false)
            showAlert("error", "Network Error, try again", "error");
        }

    }

    return isloading === true ?
        (<Loader />) : (
            <>
                <PageTitle title={["Administartor", "Change of Programme"]} />

                <ReportTable title={`Change of Programme`} columns={column} data={data} height={tableHeight}
                />
            </>
        )
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};

export default connect(mapStateToProps, null)(ChangeOfProgramme);
