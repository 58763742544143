import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
// import Loader from "../common/loader";
import PageTitle from "../common/pagetitle/pagetitle";
import { showAlert } from "../common/sweetalert/sweetalert";
import {generateToken, serverLink} from "../url";
import { useLocation } from "react-router-dom";
import { isPossiblePhoneNumber } from 'libphonenumber-js'
import { StateData } from '../state_data';
// const randomToken = require('random-token');

const AddApplicant = (props) => {
    const [error, setError] = useState(null);
    const [phoneNumberMessage, setPhoneNumberMessage] = useState(null);
    const location = useLocation();
    const [captcha, setCaptcha] = useState(generateToken(6));
    const [stateList, setStateList] = useState([]);
    const [lgaList, setLGAList] = useState([]);
    const [courseList, setCourseList] = useState([]);


    const [createItem, setCreateItem] = useState({
        firstName: "",
        middleName: "",
        surname: "",
        emailAddress: "",
        phoneNumber: "",
        Gender: "",
        lga: '',
        state: '',
        trainingMode: '',
        IsDisabled: "",
        HasLaptop: "",
        highestQualification: "",
        employmentStatus: "",
        areaOfTraining: "",
        organisation: "",
        jobLevel: "",
        appCaptcha: "",
        CourseID:"",
        Nin:""
    });

    const isValidEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    const isPhoneNumberValid = (phoneNumber) => {
        const isValid = isPossiblePhoneNumber(phoneNumber)

        if (isValid) {
            setPhoneNumberMessage('valid');
        } else {
            setPhoneNumberMessage('invalid')
        }
    };

    const reloadCaptcha = () => {
        setCaptcha(generateToken(6))
    }

    const resetItem = () => {
        setCreateItem({
            ...createItem,
            firstName: "",
            middleName: "",
            surname: "",
            emailAddress: "",
            phoneNumber: "",
            Gender: "",
            lga: '',
            state: '',
            trainingMode: '',
            IsDisabled: "",
            HasLaptop: "",
            highestQualification: "",
            employmentStatus: "",
            areaOfTraining: "",
            organisation: "",
            jobLevel: "",
            appCaptcha: "",
            CourseID:"",
            Nin:""
        });
    };

    const onEdit = async (e) => {
        const id = e.target.id;
        const value = e.target.value;

        if (id === 'emailAddress') {
            const result = isValidEmail(value);

            if (result === null) {
                setError('invalid');
            } else {
                setError('valid');
            }
        }

        if (id === 'phoneNumber') {
            isPhoneNumberValid(value);
        }
        setCreateItem({
            ...createItem,
            [id]: value,
        });
    }

    const applyNow = async () => {
        for (let key in createItem) {
            if (createItem.hasOwnProperty(key) && key !== "middleName") {
                if (createItem[key] === "") {
                    await showAlert("EMPTY FIELD", `Please enter ${key}`, "error");
                    return false;
                }
            }
        }

        if (error === "invalid") {
            showAlert('Input Error', 'Invalid Email Address', 'error');
            return false;
        }

        if (phoneNumberMessage === "invalid") {
            showAlert('Input Error', 'Invalid Phone Number', 'error');
            return false;
        }

        if (createItem.appCaptcha !== captcha) {
            showAlert('Captcha Error', 'Invalid Captcha Code', 'error');
            return false;
        }

        const formData = {
            FirstName: createItem.firstName,
            MiddleName: createItem.middleName,
            Surname: createItem.surname,
            EmailAddress: createItem.emailAddress,
            PhoneNumber: createItem.phoneNumber,
            Gender: createItem.Gender,
            State: createItem.state,
            LGA: createItem.lga,
            TrainingMode: createItem.trainingMode,
            IsDisabled: createItem.IsDisabled,
            HasLaptop: createItem.HasLaptop,
            HighestQualification: createItem.highestQualification,
            AreaOfTraining: createItem.areaOfTraining,
            EmploymentStatus: createItem.employmentStatus,
            Organisation: createItem.organisation,
            JobLevel: createItem.jobLevel,
            CourseID: createItem.CourseID,
            Nin: createItem.Nin,
        }

        await axios.post(`${serverLink}foundation/admin/application`, formData)
            .then(async res => {
                if (res.data.message === 'success') {
                    showAlert('APPLICATION SUCCESSFUL', 'We are pleased to inform you that your application has been successfully received. Our team will review and process your submission, and the outcome of your application will be communicated to you through the email address you have registered with us.', 'success');
                    const sendEmailData = {
                        from: 'ideas@bazeuniversity.edu.ng',
                        to: createItem.emailAddress,
                        name: `${createItem.firstName} ${createItem.middleName} ${createItem.surname}`,
                        app_id: res.data.app_id,
                        course: createItem.CourseID
                    }
                    await axios.post(`${serverLink}foundation/send_email`, sendEmailData).then(r => {
                        setTimeout(() => {
                            resetItem();
                            reloadCaptcha();
                        }, 2000)
                    })
                } else if (res.data.message === 'exist') {
                    showAlert('Duplication Entry', 'You have already applied. Kindly exercise patient while we are working to get back to you!', 'error');
                } else {
                    showAlert('Error Submitting', 'Something went wrong submitting your application', 'error');
                }
            })
            .catch(err => {
                showAlert('Network Error: 500', 'Please check your network connection and try again!', 'error');
            })
    }

    useEffect(() => {
        setStateList([...new Set(StateData.map(item => item.StateName))])
        reloadCaptcha();
    }, [location.pathname]);

    const getData = async () => {
        await axios.get(`${serverLink}foundation/login/get-course-list`).then((res) => {
            if (res.data.length > 0) {
                setCourseList(res.data)
            }
        })
    }

    useEffect(() => {
        getData();
    }, [])

    return (
            <>
                <PageTitle title={["Administrator", "Add Applicant"]} />
                <section className="section">
                    <div className="row align-items-top">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="d-flex justify-content-start">
                                        <h4>Add Applicant</h4>
                                    </div>
                                    <div> <h4 className="text-danger">Please note: Once submitted, names cannot be altered</h4></div>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                            <div className="col-md-4  mb-3">
                                                <input
                                                    type="text"
                                                    placeholder="First Name*"
                                                    id={"firstName"}
                                                    className="form-control"
                                                    required
                                                    value={createItem.firstName}
                                                    onChange={onEdit}
                                                />
                                            </div>
                                        <div className="col-md-4">
                                                <input
                                                    type="text"
                                                    placeholder="Middle Name"
                                                    id={"middleName"}
                                                    className="form-control"
                                                    required
                                                    value={createItem.middleName}
                                                    onChange={onEdit}
                                                />
                                        </div>
                                        <div className="col-md-4">
                                                <input
                                                    type="text"
                                                    placeholder="Surname*"
                                                    id={"surname"}
                                                    className="form-control"
                                                    value={createItem.surname}
                                                    onChange={onEdit}

                                                />
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <div className="country-select">
                                                <select id="Gender"
                                                        className="form-control"
                                                        required
                                                        value={createItem.Gender}
                                                        onChange={onEdit}>
                                                    <option value="">Select Gender</option>
                                                    <option value="Male">Male</option>
                                                    <option value="Female">Female</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                                <input
                                                    type="email"
                                                    placeholder="Email*"
                                                    required
                                                    id={"emailAddress"}
                                                    className="form-control"
                                                    value={createItem.emailAddress}
                                                    onChange={onEdit}
                                                />

                                                {error === "invalid" && (
                                                    <span style={{ backgroundColor: 'red', color: 'white', fontSize: '12px', padding: '5px', marginTop: '5px' }}>
                                                                {error && (
                                                                    <>
                                                                        {error === "invalid" && "Email is not valid"}
                                                                    </>
                                                                )}
                                                            </span>
                                                )}
                                        </div>
                                        <div className="col-md-4">
                                                <input
                                                    type="tex"
                                                    placeholder="Phone Number: +23408136791100"
                                                    id={"phoneNumber"}
                                                    required
                                                    className="form-control"
                                                    value={createItem.phoneNumber}
                                                    onChange={onEdit}
                                                />
                                                {phoneNumberMessage === "invalid" && (
                                                    <span style={{ backgroundColor: 'red', color: 'white', fontSize: '12px', padding: '5px', marginTop: '5px' }}>
                                                                {phoneNumberMessage && (
                                                                    <>
                                                                        {phoneNumberMessage === "invalid" && "Invalid phone number, it must be in this format: +23408136791100"}
                                                                    </>
                                                                )}
                                                            </span>
                                                )}
                                        </div>
                                        <div className="col-md-4 mb-3">
                                                <input
                                                    type="text"
                                                    placeholder="Organisation / Place of Work"
                                                    id={"organisation"}
                                                    required
                                                    className="form-control"
                                                    value={createItem.organisation}
                                                    onChange={onEdit} />
                                        </div>
                                        <div className="col-md-4">
                                            <div className="country-select">
                                                <select
                                                    id="highestQualification"
                                                    className="form-control"
                                                    required
                                                    value={createItem.highestQualification}
                                                    onChange={onEdit}>
                                                    <option value="">Highest Qualification</option>
                                                    <option value="SSCE">SSCE</option>
                                                    <option value="Diploma">Diploma</option>
                                                    <option value="NCE">NCE</option>
                                                    <option value="BSc">BSc</option>
                                                    <option value="Postgraduate Diploma">Postgraduate Diploma</option>
                                                    <option value="MSc">MSc</option>
                                                    <option value="MBA">MBA</option>
                                                    <option value="PhD">PhD</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                                <select id="CourseID"
                                                        className="form-control"
                                                        required
                                                        value={createItem.CourseID}
                                                        onChange={onEdit}>
                                                    <option value="">Course Name</option>
                                                    {
                                                        courseList.length > 0 &&
                                                        courseList.map((x, i) => {
                                                            return <option key={i} value={x.EntryID}>{x.CourseName}</option>
                                                        })
                                                    }
                                                </select>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="country-select">
                                                <select id="state" className="form-control" value={createItem.state}
                                                        onChange={(e) => {
                                                            setCreateItem({ ...createItem, [e.target.id]: e.target.value, nationality: e.target.value === 'Non-Nigerian' ? 'Non-Nigerian' : 'Nigeria' });
                                                            setLGAList([...new Set(StateData.filter(p => p.StateName === e.target.value).map(item => item.LGAName))]);
                                                        }}>
                                                    <option value="">Select Preferred Training State</option>
                                                    {
                                                        stateList.length > 0 &&
                                                        stateList.map((state, i) => {
                                                            return <option key={i} value={state}>{state}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <div className="country-select">
                                                <select id="lga" className="form-control" value={createItem.lga}
                                                        onChange={(e) => {
                                                            setCreateItem({ ...createItem, [e.target.id]: e.target.value });
                                                        }}>
                                                    <option value="">Select Preferred Training LGA</option>
                                                    {
                                                        lgaList.length > 0 &&
                                                        lgaList.map((lga, i) => {
                                                            return <option key={i} value={lga}>{lga}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="country-select">
                                                <select id="areaOfTraining"
                                                        className="form-control"
                                                        required
                                                        value={createItem.areaOfTraining}
                                                        onChange={onEdit}>
                                                    <option value="">Area Of Training</option>
                                                    <option value="Artificial Intelligence">Artificial Intelligence</option>
                                                    <option value="Accounting">Accounting</option>
                                                    <option value="Architecture">Architecture</option>
                                                    <option value="Banking and Finance">Banking and Finance</option>
                                                    <option value="Business Analytics">Business Analytics</option>
                                                    <option value="Business Administration">Business Administration</option>
                                                    <option value="Blockchain">Blockchain</option>
                                                    <option value="Computer Hardware">Computer Hardware</option>
                                                    <option value="Cloud Computing">Cloud Computing</option>
                                                    <option value="Computer Networks">Data Science</option>
                                                    <option value="Data Analytics">Data Analytics</option>
                                                    <option value="Economics">Economics</option>
                                                    <option value="Engineering">Engineering</option>
                                                    <option value="Human Resource">Human Resource</option>
                                                    <option value="Law">Law</option>
                                                    <option value="Mathematics">Mathematics</option>
                                                    <option value="Machine Learning">Machine Learning</option>
                                                    <option value="Military Personnel">Military Personnel</option>
                                                    <option value="Medicine">Medicine</option>
                                                    <option value="Network Security">Network Security</option>
                                                    <option value="Paramilitary Personnel">Paramilitary Personnel</option>
                                                    <option value="Physics">Physics</option>
                                                    <option value="Software Development">Software Development</option>
                                                    <option value="Others">Others</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="country-select">
                                                <select id="trainingMode"
                                                        className="form-control"
                                                        required
                                                        value={createItem.trainingMode}
                                                        onChange={onEdit}>
                                                    <option value="">Preferred Method of Training</option>
                                                    <option value="Physical">Physical</option>
                                                    <option value="Virtual">Virtual</option>
                                                    <option value="Hybrid">Hybrid</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <div className="country-select">
                                                <select id="employmentStatus"
                                                        className="form-control"
                                                        required
                                                        value={createItem.employmentStatus}
                                                        onChange={onEdit}>
                                                    <option value="">Employment Status</option>
                                                    <option value="Employed">Employed</option>
                                                    <option value="Unemployed">Unemployed</option>
                                                    <option value="Self-Employed">Self-Employed</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="country-select">
                                                <select id="jobLevel"
                                                        className="form-control"
                                                        required
                                                        value={createItem.jobLevel}
                                                        onChange={onEdit}>
                                                    <option value="">Job Level</option>
                                                    <option value="Entry-leve">Entry-level</option>
                                                    <option value="Junior">Junior</option>
                                                    <option value="Junior Executive">Junior Executive</option>
                                                    <option value="Senior">Senior</option>
                                                    <option value="Senior Executive">Senior Executive</option>
                                                    <option value="Mid-Management">Mid-Management</option>
                                                    <option value="Management">Management</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="country-select">
                                                <select id="HasLaptop"
                                                        className="form-control"
                                                        required
                                                        value={createItem.HasLaptop}
                                                        onChange={onEdit}>
                                                    <option value="">Do you have Laptop?</option>
                                                    <option value="1">Yes</option>
                                                    <option value="0">No</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <div className="country-select">
                                                <select id="IsDisabled"
                                                        className="form-control"
                                                        required
                                                        value={createItem.IsDisabled}
                                                        onChange={onEdit}>
                                                    <option value="">Do you have any disability?</option>
                                                    <option value="1">Yes</option>
                                                    <option value="0">No</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <div className="country-select">
                                            <input
                                                    type="text"
                                                    placeholder="NIN"
                                                    id={"Nin"}
                                                    required
                                                    className="form-control"
                                                    value={createItem.Nin}
                                                    onChange={onEdit} />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <h3>{captcha}</h3>
                                            <button onClick={reloadCaptcha}>Reload</button>
                                        </div>
                                        <div className="col-md-9">
                                                <input
                                                    type="text"
                                                    placeholder="Enter Captcha"
                                                    id={"appCaptcha"}
                                                    value={createItem.appCaptcha}
                                                    onChange={onEdit}
                                                />
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 text-center">
                                                <button type="submit" className="btn btn-lg btn-primary col-md-6" style={{ backgroundColor: "#63656a" }} onClick={applyNow}>Apply</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
}
const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};

export default connect(mapStateToProps, null)(AddApplicant);
