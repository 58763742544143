import { SentimentSatisfiedAltSharp } from "@material-ui/icons";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import Loader from "../common/loader";
import PageTitle from "../common/pagetitle/pagetitle";
import { showAlert } from "../common/sweetalert/sweetalert";
import { sendMail, serverLink, bucketUrl } from "../url";
// import SimpleFileUpload from "react-simple-file-uploads";
// import { simpleFileUploadAPIKey, simpleFileUploadFolderName } from "../resources/constants";

const DocumentUploads = (props) => {
  const [isloading, setIsLoading] = useState(true);

  const param = window.location.href.split("&")[1];
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);

  const [formData, setFormData] = useState({
    FileName: "",
    File: "",
    Email: props.loginData[0].EmailAddress,
    SpeakerID: param !== undefined ? param : props.loginData[0].EntryID,
    type: "",
  });

  const getData = async () => {
    await axios
      .get(
        `${serverLink}conference/dashboard/document-list/${formData.SpeakerID}`
      )
      .then((res) => {
        if (res.data.length > 0) {
          setFiles(res.data);
        }
        setIsLoading(false);
      });
  };
  const UploadFile = async (e) => {
    e.preventDefault();

    setLoading(true);
    let dt_ = new FormData();
    dt_.append("FileName", formData.FileName);
    dt_.append("File", formData.File);
    dt_.append("Email", formData.Email);
    dt_.append("SpeakerID", formData.SpeakerID);

    try {
      await axios
        .post(`${serverLink}conference/dashboard/uploadPapers`, dt_)
        .then((res) => {
          if (res.data.message === "success") {
            setLoading(false);
            getData();
            showAlert("success", "Paper uploaded successfully.", "success");
          } else {
            setLoading(false);
            showAlert("error", "Error uploading file", "error");
          }
        });
    } catch (e) {
      setLoading(false);
    }
  };

  const onEdit = (e) => {
    if (e.target.id === "File") {
      setFormData({
        ...formData,
        [e.target.id]: e.target.files[0],
        type: e.target.files[0].type,
      });
    } else {
      setFormData({
        ...formData,
        [e.target.id]: e.target.value,
      });
    }
  };

  const onDocumentChange = (url) => {
    setFormData({
      ...formData,
      File: url,
    });
  };

  useEffect(() => {
    getData();
  }, []);
  return isloading ? (
    <Loader />
  ) : (
    <>
      <PageTitle title={["Home", "Document Uploads"]} />
      <section className="section">
        <div className="row align-items-top">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Document Upload</h5>
                <form onSubmit={UploadFile}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="row mb-3">
                        <div className="col-md-8 col-lg-9">
                          <label
                            htmlFor="FileName"
                            className="col-md-4 col-lg-6 col-form-label"
                          >
                            Document Title
                          </label>
                          <textarea
                            type="text"
                            className="form-control"
                            id="FileName"
                            value={formData.FileName}
                            required
                            onChange={onEdit}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="row mb-3">
                        <div className="col-md-8 col-lg-9">
                          <label
                            htmlFor="File"
                            className="col-md-12 col-form-label"
                          >
                            Document (
                            <small className="text-danger">
                              Only pdf file is allowed, max file size is 2mb
                            </small>
                            )
                          </label>
                          <input
                            type="file"
                            accept=".pdf"
                            className="form-control"
                            id="File"
                            required
                            onChange={onEdit}
                          />
                          {/* <SimpleFileUpload
                                                        apiKey={simpleFileUploadAPIKey}
                                                        tag={simpleFileUploadFolderName}
                                                        onSuccess={onDocumentChange}
                                                        maxFileSize={2}
                                                        accepted={".pdf"}
                                                        preview={"false"}
                                                        width={"100%"}
                                                        height={"100"}
                                                    /> */}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="row mb-3">
                        <div className="col-md-8 col-lg-9">
                          <button
                            className="btn btn-sm btn-primary"
                            type="submit"
                            disabled={loading ? true : false}
                          >
                            {loading === true && (
                              <>
                                <span
                                  className="spinner-grow spinner-grow-sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                                &emsp;
                              </>
                            )}
                            {loading === true
                              ? "Loading..."
                              : "Upload Document"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section">
        <div className="row align-items-top">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Uploaded Files</h5>
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Document</th>
                      <th scope="col">File</th>
                      <th scope="col">View</th>
                    </tr>
                  </thead>
                  <tbody>
                    {files.length > 0 ? (
                      files.map((x, i) => {
                        return (
                          <tr key={i}>
                            <th scope="row">{i + 1}</th>
                            <td>{x.FileName}</td>
                            <td>
                              {/* <a target={"_blank"} href={`${bucketUrl}${x.File}`}>
                                                                    <i className="bi bi-folder-check" />
                                                                </a> */}
                              <a
                                target={"_blank"}
                                href={`${serverLink}public/uploads/conference/papers/${x.File}`}
                              >
                                <i className="bi bi-folder-check" />
                              </a>
                            </td>
                            <td>
                              {/* <a className="btn btn-sm btn-secondary" target={"_blank"} href={
                                                                    x.File.includes("simplefileupload") ? x.File : 
                                                                    `${bucketUrl}${x.File}`
                                                                }>
                                                                    Download
                                                                </a> */}
                              <a
                                className="btn btn-sm btn-secondary"
                                target={"_blank"}
                                href={
                                  x.File !== null
                                    ? `${serverLink}public/uploads/conference/papers/${x.File}`
                                    : ""
                                }
                              >
                                Download
                              </a>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={5}>No Files Uploaded</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    loginData: state.LoginDetails,
  };
};

export default connect(mapStateToProps, null)(DocumentUploads);
