import React from "react";
import {serverLink} from "../url";

function ManagementCard({ item }) {
    const containerStyle = {
        width: '25%', // Each card occupies 25% of the row width
        padding: '10px',
        boxSizing: 'border-box',
        position: 'relative',
        display: 'inline-block',
        verticalAlign: 'top',
    };

    const cardStyle = {
        position: 'relative',
        paddingBottom: '100%', // Maintain aspect ratio (1:1)
        // overflow: 'hidden',
        borderRadius: '5px'
    };

    const imageStyle = {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        objectFit: 'cover', // Maintain aspect ratio
    };

    const detailsContainerStyle = {
        backgroundColor: '#fff',
        padding: '5px',
        borderTop: '1px solid #ccc',
        textAlign: 'center',
        fontSize: '11px'
    };

    const logoStyle = {
        position: 'absolute',
        bottom: 0,
        right: 0,
        transform: 'translate(-30%, 50%)', // Center horizontally and vertically
        zIndex: 1, // Ensure logo is above the image
        width: '50px', // Adjust as needed
        height: '40px', // Adjust as needed
        borderRadius: '100%'
    };

    return (
        <div style={containerStyle}>
            <div className="card" style={cardStyle}>
                <img src={`https://ideas.api.bazeuniversity.edu.ng:5001/public/uploads/ideas_project/team/${item.ImageUrl}`} alt={item.FullName} style={imageStyle} />
                <img src={require('../img/grade.png')} style={logoStyle} />
            </div>
            <div style={detailsContainerStyle}>
                <div style={{fontSize: '11px', marginBottom: '5px', color: '#437340'}}><strong>{item.FullName}</strong> </div>
                <span><strong>{item.Designation}</strong></span><br/>
                <span>{item.EmailAddress}</span><br/>
            </div>
        </div>
    );
}

export default function ManagementRow({ items }) {
    const rowStyle = {
        margin: '0 -10px',
        textAlign: 'center',
    };

    return (
        <div style={rowStyle}>
            {items.map((element, index) => (
                <ManagementCard key={index} item={element} />
            ))}
        </div>
    );
}