import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { Link, useNavigate } from 'react-router-dom'
import { setLoginDetails, setApplicantDetails } from "../../../action/actions";
import {formatDate, formatDateAndTime, projectLogo, serverLink} from "../../url";
import { showAlert } from "../sweetalert/sweetalert";
import male_avater from '../../img/male_avater.jpg'


const Header = (props) => {
    const navigate = useNavigate();
    const [todaysSession, setTodaysSession] = useState([]);
    const [messages, setmessages] = useState([]);

    const onLogout = () => {
        props.setOnLoginDetails([]);
        props.setOnApplicantDetails([]);
        navigate("/login")
        // setTimeout(() => {
        //     navigate("/login")
        // }, 200);
    }

    const getData = async () => {
        await axios.get(`${serverLink}dashboard/sessions`)
            .then((res) => {
                if (res.data.length > 0) {
                    let yourDate = new Date();
                    let current_formatted_date = yourDate.toISOString().split('T')[0];

                    let todays_session = res.data.filter(x =>
                        formatDateAndTime(x.Date, "date") === formatDateAndTime(current_formatted_date.toString(), "date"));
                    setTodaysSession(todays_session)
                }
            })
            .catch(() => {

            })
        await axios.post(`${serverLink}dashboard/messages`, {Email: props.loginData[0].EmailAddress})
            .then((res) => {
                if (res.data.length > 0) {
                    setmessages(res.data)
                }
            })
            .catch(() => {

            })
    }
    useEffect(() => {
      //  getData();
    }, [])

    return (
        <>
            <header id="header" className="header fixed-top d-flex align-items-center hidenav">
                <div className="d-flex align-items-center justify-content-between">
                    <a href="#" className="logo d-flex align-items-center">
                         {/*<img  className="d-none d-sm-block" src={projectLogo} width="20px" height="20px"  alt="logo"/>*/}
                        <span style={{marginRight: "50px", fontSize: "20px"}}>Dashboard</span>
                    </a>
                    <i className="bi bi-list toggle-sidebar-btn" onClick={props.toggleSidebar} />
                </div>{/* End Logo */}
                <div className="search-bar">
                    <form className="search-form d-flex align-items-center">
                        <input type="text" name="query" placeholder="Search" title="Enter search keyword" />
                        <button type="submit" title="Search"><i className="bi bi-search" /></button>
                    </form>
                </div>{/* End Search Bar */}
                <nav className="header-nav ms-auto">
                    <ul className="d-flex align-items-center">
                        <li className="nav-item d-block d-lg-none">
                            <a className="nav-link nav-icon search-bar-toggle " href="#">
                                <i className="bi bi-search" />
                            </a>
                        </li>{/* End Search Icon*/}
                        <li className="nav-item dropdown">
                            <a className="nav-link nav-icon" href="#" data-bs-toggle="dropdown">
                                <i className="bi bi-bell" />
                                <span className="badge bg-primary badge-number">{todaysSession.length}</span>
                            </a>{/* End Notification Icon */}
                            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow notifications">
                                <li className="dropdown-header">
                                    You have {todaysSession.length} sessions today
                                </li>
                                <li>
                                    <hr className="dropdown-divider" />
                                </li>
                                {
                                    todaysSession.length > 0 ?
                                        <>
                                            {
                                                todaysSession.map((x, i) => {
                                                    return (
                                                        <li className="notification-item" key={i}>
                                                            <i className="bi bi-exclamation-circle text-info" />
                                                            <Link to="/notice">
                                                                <h4>{x.Title}</h4>
                                                                <p>{x.Venue}</p>
                                                                <p>{x.StartTime} - {x.EndTime}</p>
                                                                <hr className="dropdown-divider" />
                                                            </Link>
                                                        </li>
                                                    )
                                                })
                                            }
                                        </>
                                        :
                                        <div>
                                            <label className="alert alert-info">No sessions today</label>
                                        </div>
                                }
                            </ul>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link nav-icon" href="#" data-bs-toggle="dropdown">
                                <i className="bi bi-chat-left-text" />
                                <span className="badge bg-success badge-number">
                                    {
                                        messages.length > 0 ?
                                        messages.filter(x=>x.Status === '0').length
                                        : 0
                                    }
                                    </span>
                            </a>
                            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow messages">
                                <li className="dropdown-header">
                                    You have {messages.length} new messages
                                </li>
                                <li>
                                    <hr className="dropdown-divider" />
                                </li>
                                {
                                    messages.length > 0 ?
                                        <>
                                            {
                                                messages.map((x, i) => {
                                                    return (
                                                        <li className="message-item" key={i}>
                                                            <Link to="/notice">
                                                                <div>
                                                                    <h4>{x.MessageTitle}</h4>
                                                                    <p>{x.MessageBody.substring(0,100)}...</p>
                                                                    <p>{formatDateAndTime(x.DateSent)}</p>
                                                                </div>
                                                            </Link>
                                                        </li>
                                                    )
                                                })
                                            }
                                        </>
                                        :
                                        <div>

                                        </div>
                                }
                                <li>
                                    <hr className="dropdown-divider" />
                                </li>
                            </ul>{/* End Messages Dropdown Items */}
                        </li>{/* End Messages Nav */}
                        <li className="nav-item dropdown pe-3">
                            <a className="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">
                                <img src={male_avater} alt="Profile" className="rounded-circle" />
                                <span className="d-none d-md-block dropdown-toggle ps-2">
                                    {props.loginData[0].EmailAddress}
                                </span>
                            </a>{/* End Profile Iamge Icon */}
                            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                                <li className="dropdown-header">
                                    <h6>{props.loginData[0].EmailAddress}</h6>
                                    <span>{props.loginData[0].Role}</span>
                                </li>
                                <li>
                                    <hr className="dropdown-divider" />
                                </li>
                                <li>
                                    <Link to="/profile" className="dropdown-item d-flex align-items-center" href="users-profile.html">
                                        <i className="bi bi-person" />
                                        <span>My Profile</span>
                                    </Link>
                                </li>

                                <li>
                                    <hr className="dropdown-divider" />
                                </li>
                                <li>
                                    <span style={{ cursor: 'pointer' }} className="dropdown-item d-flex align-items-center" onClick={onLogout}>
                                        <i className="bi bi-box-arrow-right" />
                                        <span>Sign Out</span>
                                    </span>
                                </li>
                            </ul>{/* End Profile Dropdown Items */}
                        </li>{/* End Profile Nav */}
                    </ul>
                </nav>{/* End Icons Navigation */}
            </header>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOnLoginDetails: (p) => {
            dispatch(setLoginDetails(p));
        },
        setOnApplicantDetails: (p) => {
            dispatch(setApplicantDetails(p));
        }
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(Header);
