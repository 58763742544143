import React from "react";
import Loader from "../common/loader";
import PageTitle from "../common/pagetitle/pagetitle";
import axios from "axios";
import { useEffect } from "react";
import { moneyFormat, serverLink } from "../url";
import { useState } from "react";
import ReportTable from "../common/table/report_table";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { showAlert, showConfirm } from "../common/sweetalert/sweetalert";
import JoditEditor from "jodit-react";
import { ProgressBar } from "react-bootstrap";

const FlagOff = (props) => {
  const editorBio = React.createRef();
  const [isloading, setIsLoading] = useState(true);
  const [IsFormLoading, setIsFormLoading] = useState(false);
  const [totalRecord, setTotalRecord] = useState(0);
  const [recordCount, setRecordCount] = useState(10000);
  const [selectedID, setSelectedID] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState([]);
  const [selectedName, setSelectedName] = useState([]);
  const [selectedPhone, setSelectedPhone] = useState([]);
  const column = [
    " ",
    "S/N",
    "APPLICATION ID",
    "FULL Name",
    "EMAIL ADDRESS",
    "Area Of Training",
  ];
  const [data, setData] = useState([]);
  const [tableHeight, setTableHeight] = useState("600px");
  const [formData, setFormData] = useState({
    msgTitle: "",
    msgSubject: "",
    msgTo: "",
    msgBody: "",
    Cohort: "",
    RejectReason: "",
    InsertedBy: props.loginData[0].EmailAddress,
    EntryID: "",
  });

  const [progress, setProgress] = useState({
    percentage: 0,
    variant: "danger",
  });

  const getData = async () => {
    await axios
      .get(`${serverLink}foundation/dashboard/applicant/flag`)
      .then((res) => {
        if (res.data.message === "success") {
          if (res.data.allRecords.length > 0) {
            setTotalRecord(res.data.allRecords[0].allRecords);
          }
          const result = res.data.data.slice(0, recordCount);
          let rows = [];
          result.map((applicant, index) => {
            rows.push([
              <td className="text-xs font-weight-bold">
                <input
                  type="checkbox"
                  id="checkItem"
                  data_email={applicant.EmailAddress}
                  data_phone={applicant.PhoneNumber}
                  data_id={applicant.EntryID}
                  data_name={`${applicant.FirstName} ${applicant.MiddleName} ${applicant.Surname}`}
                  data={JSON.stringify(applicant)}
                  className="checkItem"
                  name="checkItem"
                  value={applicant.EntryID}
                  onChange={onCheck}
                />
              </td>,
              index + 1,
              applicant.EntryID,
              applicant.FullName,
              applicant.EmailAddress,
              applicant.AreaOfTraining,
            ]);
          });
          setTableHeight(result.length > 100 ? "700px" : "600px");
          setData(rows);
          setIsLoading(false);
        } else {
          toast.error("There are no new application available");
        }
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const unCheckAll = async () => {
    let checkBox = await document.getElementsByClassName("checkItem");
    for (let i = 0; i < checkBox.length; i++) {
      if (checkBox[i].checked) {
        checkBox[i].checked = false;
      }
    }
  };

  const checkAll = async () => {
    let checkBox = await document.getElementsByClassName("checkItem");

    for (let i = 0; i < checkBox.length; i++) {
      let applicantEmail = checkBox[i].getAttribute("data_email");
      let applicantPhone = checkBox[i].getAttribute("data_phone");
      let applicantID = checkBox[i].getAttribute("data_id");
      let applicantName = checkBox[i].getAttribute("data_name");
      if (!checkBox[i].checked) {
        checkBox[i].checked = true;
        setSelectedID((prevState) => [...prevState, applicantID]);
        setSelectedEmail((prevState) => [...prevState, applicantEmail]);
        setSelectedPhone((prevState) => [...prevState, applicantPhone]);
        setSelectedName((prevState) => [...prevState, applicantName]);
      } else {
        checkBox[i].checked = false;
        setSelectedID([]);
        setSelectedEmail([]);
        setSelectedPhone([]);
        setSelectedName([]);
      }
    }
  };

  const onCheck = (e) => {
    let applicantEmail = e.target.getAttribute("data_email");
    let applicantPhone = e.target.getAttribute("data_phone");
    let applicantID = e.target.getAttribute("data_id");
    let applicantName = e.target.getAttribute("data_name");

    if (e.target.checked) {
      setSelectedID((prevState) => [...prevState, applicantID]);
      setSelectedEmail((prevState) => [...prevState, applicantEmail]);
      setSelectedPhone((prevState) => [...prevState, applicantPhone]);
      setSelectedName((prevState) => [...prevState, applicantName]);
    } else {
      let newSelectedID = selectedID.filter(
        (e) => e.toString() !== applicantID.toString()
      );
      let newSelectedEmail = selectedEmail.filter(
        (e) => e.toString() !== applicantEmail.toString()
      );
      let newSelectedPhone = selectedPhone.filter(
        (e) => e.toString() !== applicantPhone.toString()
      );
      let newSelectedName = selectedName.filter(
        (e) => e.toString() !== applicantName.toString()
      );

      setSelectedID(newSelectedID);
      setSelectedEmail(newSelectedEmail);
      setSelectedPhone(newSelectedPhone);
      setSelectedName(newSelectedName);
    }

    console.log(selectedID);
  };

  useEffect(() => {
    getData();
  }, [recordCount]);

  const onEdit = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  const sendValidationLink = async () => {
    if (selectedID.length < 1) {
      showAlert("EMPTY FIELD", "Please select at least one applicant", "error");
      return false;
    }

    setIsFormLoading(true);

    selectedID.map(async (item, index) => {
      const sendEmailData = {
        from: "do-not-reply@bazeuniversity.edu.ng",
        to: selectedEmail[index],
        name: selectedName[index],
        applicantID: selectedID[index],
        //link: `https://ideas.bazeuniversity.edu.ng/validate/${btoa(selectedID[index])}`,
      };
      await axios
        .post(`${serverLink}foundation/flag-email`, sendEmailData)
        .then((r) => {
          toast.success("Sent successfully");
        });

      const percentage = ((index + 1) / selectedID.length) * 100;
      let variant = "";
      if (percentage <= 25) variant = "danger";
      else if (percentage > 25 && percentage <= 50) variant = "warning";
      else if (percentage > 50 && percentage <= 75) variant = "info";
      else variant = "success";

      setProgress({
        ...progress,
        percentage: Math.round(percentage),
        variant: variant,
      });

      if (index + 1 === selectedID.length) {
        toast.info("Operation Completed successfully");
        setIsFormLoading(false);
        setFormData({
          ...formData,
          msgTitle: "",
          msgSubject: "",
          msgTo: "",
          msgBody: "",
        });
        setSelectedID([]);
        setSelectedEmail([]);
        setSelectedName([]);
        unCheckAll();
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    });
  };

  return isloading === true ? (
    <Loader />
  ) : (
    <>
      <PageTitle title={["Administrator", "Send Mail"]} />
      <div
        className="d-flex justify-content-between mb-2"
        data-kt-customer-table-toolbar="base"
      >
        <div className="form-check form-switch">
          <input
            className="form-check-input checkAll"
            type="checkbox"
            role="switch"
            onChange={() => checkAll()}
            id="checkAll"
          />
          <label className="form-check-label" htmlFor="checkAll">
            Select all applicants
          </label>
        </div>
        <div>
          <select
            id="count"
            value={recordCount}
            onChange={(event) => setRecordCount(event.target.value)}
          >
            <option value="500">500</option>
            <option value="1000">1000</option>
            <option value="5000">5000</option>
            <option value="10000">10000</option>
          </select>
          <label className="form-check-label" htmlFor="count">
            {" "}
            &nbsp; of {moneyFormat(totalRecord)}{" "}
          </label>
        </div>
        {selectedID.length > 0 ? (
          IsFormLoading ? (
            <button
              type="button"
              className="btn btn-sm btn-primary text-white mb-4"
            >
              Please wait...
            </button>
          ) : (
            <button
              className="btn btn-sm btn-primary text-white mb-4"
              onClick={() => {
                showConfirm(
                  "Warning",
                  `Are you sure you want to send extension to the selected applicant(s)?`,
                  "warning"
                ).then(async (confirm) => {
                  if (confirm) {
                    await sendValidationLink();
                  }
                });
              }}
            >
              <i className="fa fa-check" /> Send Mail
            </button>
          )
        ) : (
          <div> </div>
        )}
      </div>
      <div className="mb-1">
        <ProgressBar
          now={progress.percentage}
          label={`${progress.percentage}%`}
          variant={progress.variant}
          striped
        />
      </div>
      <ReportTable
        title={`Send Extension`}
        columns={column}
        data={data}
        height={tableHeight}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loginData: state.LoginDetails,
    applicantData: state.ApplicantDetails,
  };
};

export default connect(mapStateToProps, null)(FlagOff);
