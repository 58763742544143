import React from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";

const SideBar = (props) => {
    return (
        <>
            <aside id="sidebar" className="sidebar">
                <ul className="sidebar-nav" id="sidebar-nav">
                    <li className="nav-item">
                        <Link className="nav-link " to="/dashboard">
                            <i className="bi bi-grid"/>
                            <span>Dashboard</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/profile">
                            <i className="bi bi-person"/>
                            <span>Profile</span>
                        </Link>
                    </li>

                    {props.loginData[0].Role === "Admin" && (
                        <>
                            <li className="nav-item">
                                <a
                                    className="nav-link collapsed"
                                    data-bs-target="#components-nav1"
                                    data-bs-toggle="collapse"
                                    href="#"
                                >
                                    <i className="bi bi-menu-button-wide"/>
                                    <span>Applications</span>
                                    <i className="bi bi-chevron-down ms-auto"/>
                                </a>
                                <ul
                                    id="components-nav1"
                                    className="nav-content collapse "
                                    data-bs-parent="#sidebar-nav"
                                >
                                    <li className="nav-item">
                                        <Link
                                            className="nav-link collapsed"
                                            to="/admin/add-new-applicant"
                                        >
                                            <i className="bi bi-list"/>
                                            <span>Add New Applicant</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link
                                            className="nav-link collapsed"
                                            to="/admin/change-of-programme"
                                        >
                                            <i className="bi bi-list"/>
                                            <span>Change of Programme</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link
                                            className="nav-link collapsed"
                                            to="/admin/new-applications"
                                        >
                                            <i className="bi bi-list"/>
                                            <span>New Applications</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link
                                            className="nav-link collapsed"
                                            to="/admin/all-applications"
                                        >
                                            <i className="bi bi-list"/>
                                            <span>All Applications</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link
                                            className="nav-link collapsed"
                                            to="/admin/approved-applications"
                                        >
                                            <i className="bi bi-check-circle"/>
                                            <span>Approved Applications</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link
                                            className="nav-link collapsed"
                                            to="/admin/rejected-applications"
                                        >
                                            <i className="bi bi-x-circle"/>
                                            <span>Rejected Applications</span>
                                        </Link>
                                    </li>
                                </ul>
                            </li>

                            <li className="nav-item">
                                <a
                                    className="nav-link collapsed"
                                    data-bs-target="#components-nav4"
                                    data-bs-toggle="collapse"
                                    href="#"
                                >
                                    <i className="bi bi-menu-button-wide"/>
                                    <span>Bulk Approve</span>
                                    <i className="bi bi-chevron-down ms-auto"/>
                                </a>
                                <ul
                                    id="components-nav4"
                                    className="nav-content collapse "
                                    data-bs-parent="#sidebar-nav"
                                >
                                    <li className="nav-item">
                                        <Link
                                            className="nav-link collapsed"
                                            to="/admin/approve-bulk"
                                        >
                                            <i className="bi bi-list"/>
                                            <span>Approve Bulk</span>
                                        </Link>
                                    </li>

                                    <li className="nav-item">
                                        <Link className="nav-link collapsed" to="/admin/re/approve">
                                            <i className="bi bi-list"/>
                                            <span>Re Approve</span>
                                        </Link>
                                    </li>
                                </ul>
                            </li>

                            <li className="nav-item">
                                <a
                                    className="nav-link collapsed"
                                    data-bs-target="#components-nav"
                                    data-bs-toggle="collapse"
                                    href="#"
                                >
                                    <i className="bi bi-menu-button-wide"/>
                                    <span>System Management</span>
                                    <i className="bi bi-chevron-down ms-auto"/>
                                </a>
                                <ul
                                    id="components-nav"
                                    className="nav-content collapse "
                                    data-bs-parent="#sidebar-nav"
                                >
                                    <li>
                                        <Link to="/admin/add-users">
                                            <i className="bi bi-circle"/>
                                            <span>Enrol Users</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/admin/add-slider">
                                            <i className="bi bi-circle"/>
                                            <span>Add Slider</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/admin/add-news">
                                            <i className="bi bi-circle"/>
                                            <span>Add News</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/admin/implementation-team">
                                            <i className="bi bi-square"/>
                                            <span>Implementation Team</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/admin/add-facilitator">
                                            <i className="bi bi-circle"/>
                                            <span>Add Facilitator</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/admin/add-course">
                                            <i className="bi bi-circle"/>
                                            <span>Add Course</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/admin/enrol-facilitator">
                                            <i className="bi bi-circle"/>
                                            <span>Enrol Facilitator</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/admin/add-module">
                                            <i className="bi bi-circle"/>
                                            <span>Add Module</span>
                                        </Link>
                                    </li>
                                </ul>
                            </li>

                            <li className="nav-item">
                                <a
                                    className="nav-link collapsed"
                                    data-bs-target="#components-nav2"
                                    data-bs-toggle="collapse"
                                    href="#"
                                >
                                    <i className="bi bi-menu-button-wide"/>
                                    <span>Reports</span>
                                    <i className="bi bi-chevron-down ms-auto"/>
                                </a>
                                <ul
                                    id="components-nav2"
                                    className="nav-content collapse "
                                    data-bs-parent="#sidebar-nav"
                                >
                                    <li className="nav-item">
                                        <Link to="/admin/reports">
                                            <i className="bi bi-grid"/>
                                            <span> Reports</span>
                                        </Link>
                                        <Link to="/admin/reports-by-state">
                                            <i className="bi bi-grid"/>
                                            <span> By State and LGA</span>
                                        </Link>
                                        <Link to="/admin/reports-by-state-verified">
                                            <i className="bi bi-grid"/>
                                            <span> By State and LGA (Verified)</span>
                                        </Link>
                                        <Link to="/admin/reports-by-state-with-passport">
                                            <i className="bi bi-grid"/>
                                            <span> By State and LGA (With Passport)</span>
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="nav-link collapsed"
                                    data-bs-target="#components-nav3"
                                    data-bs-toggle="collapse"
                                    href="#"
                                >
                                    <i className="bi bi-check-circle"/>
                                    <span>Application Processing</span>
                                    <i className="bi bi-chevron-down ms-auto"/>
                                </a>
                                <ul
                                    id="components-nav3"
                                    className="nav-content collapse "
                                    data-bs-parent="#sidebar-nav"
                                >
                                    <li className="nav-item">
                                        <Link to="/admin/validation">
                                            <i className="bi bi-grid"/>
                                            <span>Applicant NIN Validation</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/admin/training-mode-enrollment">
                                            <i className="bi bi-grid"/>
                                            <span>Training Mode Enrollment</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/admin/student-tag">
                                            <i className="bi bi-grid"/>
                                            <span>Student Tag</span>
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="nav-link collapsed"
                                    data-bs-target="#components-nav4"
                                    data-bs-toggle="collapse"
                                    href="#"
                                >
                                    <i className="bi bi-check-circle"/>
                                    <span>Notification</span>
                                    <i className="bi bi-chevron-down ms-auto"/>
                                </a>
                                <ul
                                    id="components-nav4"
                                    className="nav-content collapse "
                                    data-bs-parent="#sidebar-nav"
                                >
                                    <li className="nav-item">
                                        <Link to="/admin/remainder">
                                            <i className="bi bi-grid"/>
                                            <span>Remainder</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/admin/extension">
                                            <i className="bi bi-grid"/>
                                            <span>Extension</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/admin/flag">
                                            <i className="bi bi-grid"/>
                                            <span>Flag-Off</span>
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="nav-link collapsed"
                                    data-bs-target="#components-nav5"
                                    data-bs-toggle="collapse"
                                    href="#"
                                >
                                    <i className="bi bi-check-circle"/>
                                    <span>Assessment</span>
                                    <i className="bi bi-chevron-down ms-auto"/>
                                </a>
                                <ul
                                    id="components-nav5"
                                    className="nav-content collapse "
                                    data-bs-parent="#sidebar-nav"
                                >
                                    <li className="nav-item">
                                        <Link to="/admin/assessment">
                                            <i className="bi bi-grid"/>
                                            <span>Mark Assessment</span>
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="nav-link collapsed"
                                    data-bs-target="#components-nav7"
                                    data-bs-toggle="collapse"
                                    href="#"
                                >
                                    <i className="bi bi-check-circle"/>
                                    <span>Internship</span>
                                    <i className="bi bi-chevron-down ms-auto"/>
                                </a>
                                <ul
                                    id="components-nav7"
                                    className="nav-content collapse "
                                    data-bs-parent="#sidebar-nav"
                                >
                                    <li className="nav-item">
                                        <Link to="/admin/internship-settings">
                                            <i className="bi bi-grid"/>
                                            <span>Internship Settings</span>
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="nav-link collapsed"
                                    data-bs-target="#components-nav6"
                                    data-bs-toggle="collapse"
                                    href="#"
                                >
                                    <i className="bi bi-image-alt"/>
                                    <span>Gallery</span>
                                    <i className="bi bi-chevron-down ms-auto"/>
                                </a>
                                <ul
                                    id="components-nav6"
                                    className="nav-content collapse "
                                    data-bs-parent="#sidebar-nav"
                                >
                                    <li className="nav-item">
                                        <Link to="/admin/photo-album">
                                            <i className="bi bi-grid"/>
                                            <span>Photo Album</span>
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                        </>
                    )}
                </ul>
            </aside>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};

export default connect(mapStateToProps, null)(SideBar);
