import React from "react";
import Loader from "../common/loader";
import PageTitle from "../common/pagetitle/pagetitle";
import axios from "axios";
import { useEffect } from "react";
import {
  decryptData,
  encryptData,
  moneyFormat, projectLogo,
  projectName,
  projectURL,
  sendEmail,
  serverLink,
} from "../url";
import { useState } from "react";
import ReportTable from "../common/table/report_table";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { showAlert, showConfirm } from "../common/sweetalert/sweetalert";
import JoditEditor from "jodit-react";
import { ProgressBar } from "react-bootstrap";
const ReApproveAdmission = (props) => {
  const editorBio = React.createRef();
  const [isloading, setIsLoading] = useState(true);
  const [IsFormLoading, setIsFormLoading] = useState(false);
  const [totalRecord, setTotalRecord] = useState(0);
  const [recordCount, setRecordCount] = useState(10000);
  const [selectedID, setSelectedID] = useState([]);
  const [selectedAppID, setSelectedAppID] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState([]);
  const [selectedName, setSelectedName] = useState([]);
  const [selectedFirstname, setSelectedFirstname] = useState([]);
  const [selectedMiddlename, setSelectedMiddlename] = useState([]);
  const [selectedSurname, setSelectedSurname] = useState([]);
  const [selectedPhone, setSelectedPhone] = useState([]);
  const [selectedStudentId, setSelectedStudentId] = useState([]);
  const [selectedGender, setSelectedGender] = useState([]);
  const [selectedDisabled, setSelectedDisabled] = useState([]);
  const [selectedLaptop, setSelectedLaptop] = useState([]);
  const [selectedCohort, setSelectedCohort] = useState([]);
  const [selectedOrganisation, setSelectedOrganisation] = useState([]);
  const [selectedQual, setSelectedQual] = useState([]);
  const [selectedTraning, setSelectedTraining] = useState([]);
  const [selectedEmployment, setSelectedEmployment] = useState([]);
  const [selectedCourseID, setSelectedCourseID] = useState([]);
  const [selectedCourseName, setSelectedCourseName] = useState([]);
  const [selectedNIN, setSelectedNIN] = useState([]);
  const [location, setLocation] = useState(false);
  const column = [
    " ",
    "S/N",
    "APPLICATION ID",
    "FULL Name",
    "EMAIL ADDRESS",
    "Area Of Training",
    "State",
  ];
  const [data, setData] = useState([]);
  const [tableHeight, setTableHeight] = useState("600px");
  const [formData, setFormData] = useState({
    msgTitle: "",
    msgSubject: "",
    msgTo: "",
    msgBody: "",
    Cohort: "",
    RejectReason: "",
    InsertedBy: props.loginData[0].EmailAddress,
    EntryID: "",
    TrainingMode: "",
    Location: "",
  });

  const [progress, setProgress] = useState({
    percentage: 0,
    variant: "danger",
  });

  const getData = async () => {
    await axios
      .get(`${serverLink}foundation/dashboard/application/re/approve`)
      .then((res) => {
        if (res.data.message === "success") {
          if (res.data.allRecords.length > 0) {
            setTotalRecord(res.data.allRecords[0].allRecords);
          }
          const result = res.data.data.slice(0, recordCount);
          let rows = [];
          result.map((applicant, index) => {
            rows.push([
              <td className="text-xs font-weight-bold">
                <input
                  type="checkbox"
                  id="checkItem"
                  data_email={applicant.EmailAddress}
                  data_phone={applicant.PhoneNumber}
                  data_studentId={applicant.StudentID}
                  data_id={applicant.EntryID}
                  data_app_id={applicant.ApplicationID}
                  data_name={`${applicant.FirstName} ${applicant.MiddleName} ${applicant.Surname}`}
                  data_firstname={applicant.FirstName}
                  data_middlename={applicant.MiddleName}
                  data_surname={applicant.Surname}
                  data_gender={applicant.Gender}
                  data_nin={applicant.NIN}
                  data_course_id={applicant.CourseID}
                  data_course_name={applicant.CourseName}
                  data_isdisabled={applicant.IsDisabled}
                  data_haslaptop={applicant.HasLaptop}
                  data_cohort={applicant.Cohort}
                  data_organisation={applicant.Organisation}
                  data_highestqualification={applicant.HighestQualification}
                  data_training={applicant.AreaOfTraining}
                  data_employment={applicant.EmploymentStatus}
                  data={JSON.stringify(applicant)}
                  className="checkItem"
                  name="checkItem"
                  value={applicant.EntryID}
                  onChange={onCheck}
                />
              </td>,
              index + 1,
              applicant.EntryID,
              applicant.FullName,
              applicant.EmailAddress,
              applicant.AreaOfTraining,
              applicant.State,
            ]);
          });
          setTableHeight(result.length > 100 ? "700px" : "600px");
          setData(rows);
          setIsLoading(false);
        } else {
          toast.error("There are no new application available");
        }
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const reloadPage = () => {
    window.location.reload();
  };

  const unCheckAll = async () => {
    let checkBox = await document.getElementsByClassName("checkItem");
    for (let i = 0; i < checkBox.length; i++) {
      if (checkBox[i].checked) {
        checkBox[i].checked = false;
      }
    }
  };

  const checkAll = async () => {
    let checkBox = await document.getElementsByClassName("checkItem");

    for (let i = 0; i < checkBox.length; i++) {
      let applicantEmail = checkBox[i].getAttribute("data_email");
      let applicantPhone = checkBox[i].getAttribute("data_phone");
      let applicantID = checkBox[i].getAttribute("data_id");
      let appID = checkBox[i].getAttribute("data_app_id");
      let studentId = checkBox[i].getAttribute("data_studentId");

      let applicantName = checkBox[i].getAttribute("data_name");

      let applicantFirstname = checkBox[i].getAttribute("data_firstname");
      let applicantMiddlename = checkBox[i].getAttribute("data_middlename");
      let applicantsurname = checkBox[i].getAttribute("data_surname");

      let appGender = checkBox[i].getAttribute("data_gender");
      let appNIN = checkBox[i].getAttribute("data_nin");
      let appCourseID = checkBox[i].getAttribute("data_course_id");
      let appCourseName = checkBox[i].getAttribute("data_course_name");
      let appDisabled = checkBox[i].getAttribute("data_isdisabled");
      let appHaslaptop = checkBox[i].getAttribute("data_haslaptop");
      let appCohort = checkBox[i].getAttribute("data_cohort");
      let appOrganisation = checkBox[i].getAttribute("data_organisation");
      let appQual = checkBox[i].getAttribute("data_highestqualification");
      let appTraining = checkBox[i].getAttribute("data_training");
      let appEmployment = checkBox[i].getAttribute("data_employment");
      if (!checkBox[i].checked) {
        checkBox[i].checked = true;
        setSelectedID((prevState) => [...prevState, applicantID]);
        setSelectedAppID((prevState) => [...prevState, appID]);
        setSelectedEmail((prevState) => [...prevState, applicantEmail]);
        setSelectedPhone((prevState) => [...prevState, applicantPhone]);
        setSelectedStudentId((prevState) => [...prevState, studentId]);

        setSelectedName((prevState) => [...prevState, applicantName]);
        setSelectedFirstname((prevState) => [...prevState, applicantFirstname]);
        setSelectedMiddlename((prevState) => [
          ...prevState,
          applicantMiddlename,
        ]);
        setSelectedSurname((prevState) => [...prevState, applicantsurname]);
        setSelectedCourseID((prevState) => [...prevState, appCourseID]);
        setSelectedCourseName((prevState) => [...prevState, appCourseName]);

        setSelectedGender((prevState) => [...prevState, appGender]);
        setSelectedNIN((prevState) => [...prevState, appNIN]);
        setSelectedDisabled((prevState) => [...prevState, appDisabled]);
        setSelectedLaptop((prevState) => [...prevState, appHaslaptop]);
        setSelectedCohort((prevState) => [...prevState, appCohort]);
        setSelectedOrganisation((prevState) => [...prevState, appOrganisation]);
        setSelectedQual((prevState) => [...prevState, appQual]);
        setSelectedTraining((prevState) => [...prevState, appTraining]);
        setSelectedEmployment((prevState) => [...prevState, appEmployment]);
      } else {
        checkBox[i].checked = false;
        setSelectedID([]);
        setSelectedAppID([]);
        setSelectedEmail([]);
        setSelectedPhone([]);
        setSelectedStudentId([]);
        setSelectedName([]);
        setSelectedFirstname([]);
        setSelectedMiddlename([]);
        setSelectedSurname([]);
        setSelectedGender([]);
        setSelectedNIN([]);
        setSelectedCourseID([]);
        setSelectedCourseName([]);
        setSelectedDisabled([]);
        setSelectedLaptop([]);
        setSelectedCohort([]);
        setSelectedOrganisation([]);
        setSelectedQual([]);
        setSelectedTraining([]);
        setSelectedEmployment([]);
      }
    }
  };

  const onCheck = (e) => {
    let applicantEmail = e.target.getAttribute("data_email");
    let applicantPhone = e.target.getAttribute("data_phone");
    let applicantID = e.target.getAttribute("data_id");
    let appID = e.target.getAttribute("data_app_id");
    let applicantName = e.target.getAttribute("data_name");
    let applicantFirstname = e.target.getAttribute("data_firstname");
    let applicantMiddlename = e.target.getAttribute("data_middlename");
    let applicantsurname = e.target.getAttribute("data_surname");
    let applicantStudentId = e.target.getAttribute("data_studentId");

    let appCourseID = e.target.getAttribute("data_course_id");
    let appCourseName = e.target.getAttribute("data_course_name");
    let appGender = e.target.getAttribute("data_gender");
    let appNIN = e.target.getAttribute("data_nin");
    let appDisabled = e.target.getAttribute("data_isdisabled");
    let appHaslaptop = e.target.getAttribute("data_haslaptop");
    let appCohort = e.target.getAttribute("data_cohort");
    let appOrganisation = e.target.getAttribute("data_organisation");
    let appQual = e.target.getAttribute("data_highestqualification");
    let appTraining = e.target.getAttribute("data_training");
    let appEmployment = e.target.getAttribute("data_employment");

    if (e.target.checked) {
      setSelectedID((prevState) => [...prevState, applicantID]);
      setSelectedAppID((prevState) => [...prevState, appID]);
      setSelectedEmail((prevState) => [...prevState, applicantEmail]);
      setSelectedPhone((prevState) => [...prevState, applicantPhone]);

      setSelectedName((prevState) => [...prevState, applicantName]);
      setSelectedFirstname((prevState) => [...prevState, applicantFirstname]);
      setSelectedMiddlename((prevState) => [...prevState, applicantMiddlename]);
      setSelectedSurname((prevState) => [...prevState, applicantsurname]);
      setSelectedCourseID((prevState) => [...prevState, appCourseID]);
      setSelectedCourseName((prevState) => [...prevState, appCourseName]);
      setSelectedStudentId((prevState) => [...prevState, applicantStudentId]);

      setSelectedGender((prevState) => [...prevState, appGender]);
      setSelectedNIN((prevState) => [...prevState, appNIN]);
      setSelectedDisabled((prevState) => [...prevState, appDisabled]);
      setSelectedLaptop((prevState) => [...prevState, appHaslaptop]);
      setSelectedCohort((prevState) => [...prevState, appCohort]);
      setSelectedOrganisation((prevState) => [...prevState, appOrganisation]);
      setSelectedQual((prevState) => [...prevState, appQual]);
      setSelectedTraining((prevState) => [...prevState, appTraining]);
      setSelectedEmployment((prevState) => [...prevState, appEmployment]);
    } else {
      let newSelectedID = selectedID.filter(
        (e) => e.toString() !== applicantID.toString()
      );
      let newSelectedAppID = selectedAppID.filter(
        (e) => e.toString() !== appID.toString()
      );
      let newSelectedEmail = selectedEmail.filter(
        (e) => e.toString() !== applicantEmail.toString()
      );
      let newSelectedPhone = selectedPhone.filter(
        (e) => e.toString() !== applicantPhone.toString()
      );

      let newSelectedStudentId = selectedStudentId.filter(
        (e) => e.toString() !== applicantStudentId.toString()
      );

      let newSelectedName = selectedName.filter(
        (e) => e.toString() !== applicantName.toString()
      );
      let newSelectedFirstname = selectedFirstname.filter(
        (e) => e.toString() !== applicantFirstname.toString()
      );
      let newSelectedMiddlename = selectedMiddlename.filter(
        (e) => e.toString() !== applicantMiddlename.toString()
      );
      let newSelectedSurname = selectedSurname.filter(
        (e) => e.toString() !== applicantsurname.toString()
      );
      let newSelectedCourseID = selectedCourseID.filter(
        (e) => e.toString() !== appCourseID.toString()
      );
      let newSelectedCourseName = selectedCourseName.filter(
        (e) => e.toString() !== appCourseName.toString()
      );

      let newSelectedGender = selectedGender.filter(
        (e) => e.toString() !== appGender.toString()
      );
      let newSelectedNIN = selectedNIN.filter(
        (e) => e.toString() !== appNIN.toString()
      );
      let newSelectedDisabled = selectedDisabled.filter(
        (e) => e.toString() !== appDisabled.toString()
      );
      let newSelectedLaptop = selectedLaptop.filter(
        (e) => e.toString() !== appHaslaptop.toString()
      );
      let newSelectedCohort = selectedCohort.filter(
        (e) => e.toString() !== appCohort.toString()
      );
      let newSelectedOrganisation = selectedOrganisation.filter(
        (e) => e.toString() !== appOrganisation.toString()
      );
      let newSelectedQual = selectedQual.filter(
        (e) => e.toString() !== appQual.toString()
      );
      let newSelectedTraining = selectedTraning.filter(
        (e) => e.toString() !== appTraining.toString()
      );
      let newSelectedEmployment = selectedEmployment.filter(
        (e) => e.toString() !== appEmployment.toString()
      );

      setSelectedID(newSelectedID);
      setSelectedAppID(newSelectedAppID);
      setSelectedEmail(newSelectedEmail);
      setSelectedPhone(newSelectedPhone);
      setSelectedName(newSelectedName);
      setSelectedFirstname(newSelectedFirstname);
      setSelectedMiddlename(newSelectedMiddlename);
      setSelectedStudentId(newSelectedStudentId);
      setSelectedSurname(newSelectedSurname);
      setSelectedGender(newSelectedGender);
      setSelectedNIN(newSelectedNIN);
      setSelectedCourseID(newSelectedCourseID);
      setSelectedCourseName(newSelectedCourseName);
      setSelectedDisabled(newSelectedDisabled);
      setSelectedLaptop(newSelectedLaptop);
      setSelectedCohort(newSelectedCohort);
      setSelectedOrganisation(newSelectedOrganisation);
      setSelectedQual(newSelectedQual);
      setSelectedTraining(newSelectedTraining);
      setSelectedEmployment(newSelectedEmployment);
    }

  };

  useEffect(() => {
    getData();
  }, [recordCount]);

  const onEdit = (e) => {
    let id = e.target.id;
    let value = e.target.value;

    if (id === "TrainingMode") {
      if (value === "Physical") setLocation(true);
      else setLocation(false);
    }
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  const sendValidationLink = async () => {
    if (selectedID.length < 1) {
      showAlert("EMPTY FIELD", "Please select at least one applicant", "error");
      return false;
    }
    if (formData.TrainingMode === "") {
      showAlert("EMPTY FIELD", "Please select training mode", "error");
      return false;
    }

    if (formData.TrainingMode === "Physical" && formData.Location === "") {
      showAlert("EMPTY FIELD", "Please select location ", "error");
      return false;
    }

    setIsFormLoading(true);

    selectedID.map(async (item, index) => {
      const sendEmailData = {
        from: "do-not-reply@bazeuniversity.edu.ng",
        // to: 'adammusa89@gmail.com',
        to: selectedEmail[index],
        name: selectedName[index],
        FirstName: selectedFirstname[index],
        MiddleName: selectedMiddlename[index],
        Surname: selectedSurname[index],
        PhoneNumber: selectedPhone[index],
        CourseID: selectedCourseID[index],
        CourseName: selectedCourseName[index],
        EmailAddress: selectedEmail[index],
        StudentID: selectedStudentId[index],
        AppID: selectedAppID[index],
        EntryID: selectedID[index],
        Cohort: "24",
        Status: "Active",
        Gender: selectedGender[index],
        IsDisabled: selectedDisabled[index],
        HasLaptop: selectedLaptop[index],
        Organisation: selectedOrganisation[index],
        HighestQualification: selectedQual[index],
        AreaOfTraining: selectedTraning[index],
        EmploymentStatus: selectedEmployment[index],
        NIN: selectedNIN[index],
        InsertedBy: formData.InsertedBy,
        Password: encryptData("123456789"),
        TrainingMode: formData.TrainingMode,
        Location: formData.Location,
        link: `https://ideas.bazeuniversity.edu.ng/admission-letter/${selectedAppID[index]}`,
        logo: projectLogo
        // link: `http://localhost:3000/admission-letter/${btoa(selectedID[index])}`,
      };

      await axios
        .post(`${serverLink}foundation/dashboard/re/approve`, sendEmailData)
        .then(async (r) => {
          if (r.data.message === "success") {
            toast.success("success");
          }
        });

      const percentage = ((index + 1) / selectedID.length) * 100;
      let variant = "";
      if (percentage <= 25) variant = "danger";
      else if (percentage > 25 && percentage <= 50) variant = "warning";
      else if (percentage > 50 && percentage <= 75) variant = "info";
      else variant = "success";

      setProgress({
        ...progress,
        percentage: Math.round(percentage),
        variant: variant,
      });

      if (index + 1 === selectedID.length) {
        toast.info("Process completed successfully");
        setIsFormLoading(false);
        setFormData({
          ...formData,
          msgTitle: "",
          msgSubject: "",
          msgTo: "",
          msgBody: "",
        });
        setSelectedID([]);
        setSelectedEmail([]);
        setSelectedName([]);
        await unCheckAll();
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    });
  };

  return isloading === true ? (
    <Loader />
  ) : (
    <>
      <PageTitle title={["Administrator", "Re Approve Admission"]} />
      <div
        className="d-flex justify-content-between mb-2"
        data-kt-customer-table-toolbar="base"
      >
        <div className="form-check form-switch">
          <input
            className="form-check-input checkAll"
            type="checkbox"
            role="switch"
            onChange={() => checkAll()}
            id="checkAll"
          />
          <label className="form-check-label" htmlFor="checkAll">
            Select all applicants
          </label>
        </div>
        <div className="col-lg-3">
          <div className="row mb-3">
            <div className="col-md-12">
              {/*<label htmlFor="Cohort" className="col-md-12 col-lg-12 col-form-label"><b>Select Training Mode</b></label>*/}
              <select
                className="form-control form-select"
                required
                onChange={onEdit}
                id="TrainingMode"
                value={formData.TrainingMode}
              >
                <option value={""}>-select Training Mode-</option>
                <option value="Online">Online</option>
                <option value="Physical">Physical</option>
              </select>
            </div>
          </div>
        </div>
        {location === true && (
          <div className="col-md-3">
            {/*<label htmlFor="Cohort" className="col-md-12 col-lg-12 col-form-label"><b>Select Training Mode</b></label>*/}
            <select
              className="form-control form-select"
              required
              onChange={onEdit}
              id="Location"
              value={formData.Location}
            >
              <option value={""}>-select Training Mode-</option>
              <option value="Baze University">Baze University</option>
              <option value="Jigawa">Jigawa State</option>
            </select>
          </div>
        )}
        <div>
          <select
            id="count"
            value={recordCount}
            onChange={(event) => setRecordCount(event.target.value)}
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="200">200</option>
            <option value="500">500</option>
            <option value="10000">10000</option>
          </select>
          <label className="form-check-label" htmlFor="count">
            {" "}
            &nbsp; of {moneyFormat(totalRecord)}{" "}
          </label>
        </div>
        {selectedID.length > 0 ? (
          IsFormLoading ? (
            <button
              type="button"
              className="btn btn-sm btn-primary text-white mb-4"
            >
              Please wait...
            </button>
          ) : (
            <button
              className="btn btn-sm btn-primary text-white mb-4"
              onClick={() => {
                showConfirm(
                  "Warning",
                  `Are you sure you want to approve and enrol to the selected applicant(s)?`,
                  "warning"
                ).then(async (confirm) => {
                  if (confirm) {
                    await sendValidationLink();
                  }
                });
              }}
            >
              <i className="fa fa-check" />
              Re Approve Admission
            </button>
          )
        ) : (
          <div>
            {" "}
            <button
              onClick={reloadPage}
              className="btn btn-sm btn-primary text-white mb-4"
            >
              <i className="fa fa-check" />
              Reload
            </button>{" "}
          </div>
        )}
      </div>
      <div className="mb-1">
        <ProgressBar
          now={progress.percentage}
          label={`${progress.percentage}%`}
          variant={progress.variant}
          striped
        />
      </div>
      <ReportTable
        title={`Re Approve Admission`}
        columns={column}
        data={data}
        height={tableHeight}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loginData: state.LoginDetails,
    applicantData: state.ApplicantDetails,
  };
};

export default connect(mapStateToProps, null)(ReApproveAdmission);
