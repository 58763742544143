import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import Loader from "../common/loader";
import PageTitle from "../common/pagetitle/pagetitle";
import { showAlert } from "../common/sweetalert/sweetalert";
import { serverLink } from "../url";
import Select from "react-select";
import ReportTable from "../common/table/report_table";
import {useLocation} from "react-router-dom";


const Assessment = (props) => {
    const [isloading, setIsLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const [moduleOptions, setModuleOptions] = useState([]);
    const [canSeeReport, setCanSeeReport] = useState(false);
    const [data, setData] = useState([]);
    const [studentScore, setStudentScore] = useState([])
    const [tableHeight, setTableHeight] = useState("600px");
    const location = useLocation();

    const columns = [
        "S/N",
        "StudentID",
        "Full Name",
        "Module Code",
        "Module Name",
        "CA",
    ];
    const [formData, setFormData] = useState({
        ModuleCode: "",
        ModuleCode2:"",
        InsertedBy: props.loginData[0].EmailAddress,
    })
    const getData = async () => {
        await axios.get(`${serverLink}foundation/login/get-module-list`).then((res) => {
            let rows=[];
            if (res.data.length > 0) {
                res.data.map((row)=>{
                    rows.push({
                        value:row.ModuleCode, label:`${row.ModuleName} (${row.ModuleCode})`,
                    })
                })
                setModuleOptions(rows)
            }
        })
        setIsLoading(false)
    }

    useEffect(() => {
        getData();
    }, [])

    const onEdit = (e) => {
        let id = e.target.id;
        let value = e.target.value;
        let score = e.target.getAttribute('max');
        if (parseInt(value) > parseInt(score)) {
            toast.error(`Score/Point for the selected field can not be more than ${score.toString()}`);
            document.getElementById(id.toString()).value = "";
            return false;
        }
    }

    const Reset = () => {
        setFormData({
            ...formData,
            ModuleCode: "",
            Status: "",
            EntryID: "",
            ModuleCode2:"",
        })
    }



    const handleModuleChange = async (e) => {
         let ModuleCode =e.value
            setFormData({
            ...formData,
            ModuleCode: e.value,
             ModuleCode2: e
            // [e.target.id]: e.target.value,
        });

        await axios.post(`${serverLink}foundation/dashboard/get-score`, {ModuleCode:ModuleCode}).then((res) => {
            setStudentScore(res.data)
        })
        await axios
            .post(`${serverLink}foundation/applicant_by_module`, formData)
            .then((res) => {
                const result = res.data;
                if (result.length > 0) {
                    let rows = [];
                    result.map((item, index) => {
                        // const matchingScore = studentScore.filter(x => x.ApplicationID === item.ApplicationID);
                        rows.push([
                            index + 1,
                            item.StudentID,
                            `${item.FirstName} ${item.MiddleName} ${item.Surname}`,
                            item.ModuleName,
                            item.ModuleCode,
                            <td className="text-xs font-weight-bold">
                            <input type="number"
                                       id="ca"
                                       data_id={item.ApplicationID}
                                       data_name={`${item.FirstName} ${item.MiddleName} ${item.Surname}`}
                                       data={JSON.stringify(item)}
                                       className="form-control ca"
                                       name="ca"
                                       min={0}
                                   max={60}
                                   // defaultValue={matchingScore ? matchingScore.Ca : 0}
                                   // defaultValue={studentScore.filter(score => score.ApplicationID === item.ApplicationID)?.Ca}

                                       onKeyDown={handleKeyDown}
                                       onChange={onEdit}
                                        />
                            </td>,
                        ]);
                    });
                    setTableHeight(result.length > 100 ? "1000px" : "600px");
                    setData(rows);
                    setCanSeeReport(true);
                } else {
                    toast.error("There is no report for this modules");
                }
                setIsLoading(false);
            })
            .catch((err) => {
                toast.error("NETWORK ERROR");
            });
    };

    const handleKeyDown = async (e) => {
        let caScoreArray = [];
        let applicantIDArray=[];
        if (e.key === 'Enter') {
            // if (e.key === 'Enter' && e.target.id === 'exam') {
            e.preventDefault();
            let appID = e.target.getAttribute("data_id");
            let caValue = document.getElementsByClassName("ca");
            for (let i = 0; i < caValue.length; i++) {
                caScoreArray.push(caValue[i].value);
                let applicantID = caValue[i].getAttribute("data_id")
                if (applicantID) {
                    applicantIDArray.push(applicantID);
                }
            }

            let sendData = {...formData, CA: caScoreArray, AppID: applicantIDArray}
            try {
                await axios.post(`${serverLink}foundation/dashboard/add/assessment`, sendData).then((res) => {
                    if (res.data.message === 'success') {
                        // getData()
                        toast.success("Score submitted successfully");
                        setTimeout(()=>{
                            window.location.reload();
                        }, 1000)
                        // setFormData({
                        //     ...formData,
                        //     ModuleCode: "",
                        //     ModuleCode2: "",
                        //     InsertedBy: props.loginData[0].EmailAddress,
                        // })
                    }else if (res.data.message === 'update'){
                        toast.success("Score updated successfully");
                        setTimeout(()=>{
                            window.location.reload();
                        }, 1000)
                    }
                })
            } catch (e) {
                console.log(e)
            }
        }
    };

    return isloading === true ?
        (<Loader />) : (
            <>
                <PageTitle title={["Administrator", "Mark Assessment"]} />
                <section className="section">
                    <div className="row align-items-top">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="d-flex justify-content-start">
                                        <h4>Mark Assessment</h4>
                                    </div>
                                    <div>
                                        <form>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="row mb-3">
                                                        <div className="col-md-12">
                                                            <label htmlFor="ModuleCode" className="col-md-12 col-lg-12 col-form-label">Module Name</label>
                                                            <Select
                                                                id="ModuleCode"
                                                                className="form-select w-100"
                                                                value={formData.ModuleCode2}
                                                                onChange={handleModuleChange}
                                                                options={moduleOptions}
                                                                placeholder="Select Module"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                                {canSeeReport ? (
                                    <div className="row">
                                        <div className="col-md-12 mt-5">
                                            {
                                                <ReportTable
                                                    title={`List of Student by Module`}
                                                    columns={columns}
                                                    data={data}
                                                    height={tableHeight}
                                                />
                                            }
                                        </div>
                                    </div>
                                ) : null}                            </div>
                        </div>
                    </div>
                </section>

            </>
        )
}
const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};

export default connect(mapStateToProps, null)(Assessment);
