import React from "react";
import {serverLink} from "../url";

function StudentCard({ students }) {
  const containerStyle = {
    width: "25%", // Each card occupies 25% of the row width
    padding: "10px",
    boxSizing: "border-box",
    position: "relative",
    display: "inline-block",
    verticalAlign: "top",
  };

  const cardStyle = {
    position: "relative",
    paddingBottom: "100%", // Maintain aspect ratio (1:1)
    // overflow: 'hidden',
    borderRadius: "5px",
  };

  const imageStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover", // Maintain aspect ratio
  };

  const detailsContainerStyle = {
    backgroundColor: "#fff",
    padding: "5px",
    borderTop: "1px solid #ccc",
    textAlign: "left",
    fontSize: "11px",
  };

  const logoStyle = {
    position: "absolute",
    bottom: 0,
    right: 0,
    transform: "translate(-30%, 50%)", // Center horizontally and vertically
    zIndex: 1, // Ensure logo is above the image
    width: "50px", // Adjust as needed
    height: "40px", // Adjust as needed
    borderRadius: "100%",
  };

  const getDisabilityStatus = (disability) => {
    if (disability === 1) {
      return "Disabled";
    }
    if (disability === 0) {
      return "Normal";
    }
  };

  return (
    // {
    //     "StudentID": "IDEAS/24/4016",
    //     "EmailAddress": "yasiraumar43@gmail.com",
    //     "PhoneNumber": "+2347069956895",
    //     "Cohort": "24",
    //     "State": "Niger",
    //     "LGA": "Chanchaga",
    //     "FirstName": "Yasira ",
    //     "MiddleName": "",
    //     "Surname": "Umar",
    //     "CourseName": "Professional Diploma in Applied Artificial Intelligence"
    // }

    <div style={containerStyle}>
      <div className="card" style={cardStyle}>
        <img
          src={`https://ideas.api.bazeuniversity.edu.ng:5001/public/uploads/ideas_project/passport/${students.Passport}`}
          alt="Student Passport"
          style={imageStyle}
        />
        <img src={require("../img/grade.png")} style={logoStyle} />
      </div>
      <div style={detailsContainerStyle}>
        <div
          style={{ fontSize: "15px", marginBottom: "5px", color: "#437340" }}
        >
          <strong>
            {students.FirstName} {students.MiddleName} {students.Surname}
          </strong>{" "}
        </div>
        <span>
          <strong>Student ID:</strong> {students.StudentID}
        </span>
        <br />
        <span>
          <strong>NIN:</strong> {students.NIN}
        </span>
        <br />
        <span>
          <strong>Gender/Disability:</strong> {students.Gender}/
          {getDisabilityStatus(students.IsDisabled)}
        </span>
        <br />
        <span>
          <strong>State/LGA:</strong> {students.State}/{students.LGA}
        </span>
        <br />
        <span>
          <strong>Course:</strong> {students.CourseName}
        </span>
        <br />
        <span>
          <strong>Email:</strong> {students.EmailAddress}
        </span>
        <br />
        <span>
          <strong>Phone:</strong> {students.PhoneNumber}
        </span>
        <br />
      </div>
    </div>
  );
}

export default function StudentRow({ students }) {
  const rowStyle = {
    margin: "0 -10px",
    textAlign: "center",
  };

  return (
    <div style={rowStyle}>
      <h4 style={{ textAlign: "left", marginLeft: "15px", color: "black" }}>
        Students
      </h4>
      <hr />
      {students.map((students, index) => (
        <StudentCard key={index} students={students} />
      ))}
    </div>
  );
}
