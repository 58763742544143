import axios from "axios";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import Loader from "../common/loader";
import PageTitle from "../common/pagetitle/pagetitle";
import { serverLink } from "../url";
import Select from "react-select";
import bg from "../img/watermark.jpg";

import { useReactToPrint } from "react-to-print";
import CardComponent from "./utils/CardComponent";

const headerStyle = {
    textAlign: "center",
    padding: "20px",
};

const hrStyle = {
    borderTop: "4px solid #333", // Darker color
    margin: "auto",
    color: "black",
    marginBottom: "20px", // Adjusted margin
};

const logoStyle = {
    width: "250px",
    height: "110px",
    marginBottom: "20px", // Adjusted margin for logo
};

const titleStyle = {
    fontSize: "24px",
    fontWeight: "bold",
    color: "black",
    marginBottom: "0", // Removed margin between the titles
};

const subtitleStyle = {
    fontSize: "20px",
    color: "black",
    marginBottom: "0", // Removed margin between the titles
};

const StudentTag = (props) => {
    const [isloading, setIsLoading] = useState(true);
    const [cohortOptions, setCohortOptions] = useState([]);
    const [management, setManagement] = useState([]);
    const [students, setStudent] = useState([]);
    const [studentPhoto, setStudentPhoto] = useState([]);
    const componentRef = useRef();
    const [printPage, setprintPage] = useState(false);
    const [albumTitle, setAlbumTitle] = useState("Photo Album");

    const columns = [
        "S/N",
        "Staff ID",
        "Staff Name",
        "Module Type",
        "Module Code",
        "Faculty Name",
        "Department Name",
        "OfficialEmailAddress",
        "Designation",
        "Phone Number",
        "Gender",
    ];
    const [formData, setFormData] = useState({
        StaffID: "",
        CohortCode: "",
        Status: "1",
        EntryID: "",
        StaffID2: "",
        CohortCode2: "",
        InsertedBy: props.loginData[0].EmailAddress,
    });
    const getData = async () => {
        setIsLoading(true);
        await axios
            .get(`${serverLink}foundation/dashboard/student/tag`)
            .then((res) => {
                let rows = [];
                if (res.data.message === "success") {
                    res.data.cohort.map((row) => {
                        rows.push({
                            value: row.CohortCode,
                            label: `${row.Cohort}`,
                        });
                    });
                    setCohortOptions(rows);
                    setManagement(res.data.management);
                    setStudent(res.data.student);
                }
                setIsLoading(false);
            });
    };

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        const filter = students.filter((e) => e.Cohort === formData.CohortCode);
        if (filter.length > 0) {
            setStudentPhoto(filter);
        } else {
            setStudentPhoto([]);
        }
    }, [formData.CohortCode]);

    const onPrintPage = () => {
        setprintPage(true);
        setTimeout(() => {
            handlePrint();
            setprintPage(false);
        }, 1000);
    };

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const handleModuleChange = async (e) => {
        setFormData({
            ...formData,
            CohortCode: e.value,
            CohortCode2: e,
        });
    };

    return isloading === true ? (
        <Loader />
    ) : (
        <>
            <PageTitle title={["Student", "Student Tag"]} />
            <section className="section">
                <div className="row align-items-top">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="d-flex justify-content-start">
                                    <h4>Student Tag</h4>
                                </div>
                                <div>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <label
                                                        htmlFor="CohortCode"
                                                        className="col-md-12 col-lg-12 col-form-label"
                                                    >
                                                        Cohort
                                                    </label>
                                                    <Select
                                                        id="CohortCode"
                                                        className="form-select w-100"
                                                        value={formData.CohortCode2}
                                                        onChange={handleModuleChange}
                                                        options={cohortOptions}
                                                        placeholder="Select Cohort"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {studentPhoto.length > 0 ? (
                            <>
                                <button
                                    type="button"
                                    className="btn btn-sm btn-primary "
                                    style={{ marginBottom: "15px" }}
                                    onClick={onPrintPage}
                                >
                                    Print
                                </button>
                                <div className="card" ref={componentRef}>
                                    <div
                                        className="card-body"

                                    >
                                        <div>
                                            <div style={headerStyle}>
                                                <div style={titleStyle}>
                                                    <h3>CBDAI-Domineum IDEAS Project, Domineum-Baze</h3>
                                                    <h4>
                                                        Students Tag
                                                    </h4>
                                                    <hr style={hrStyle} />
                                                </div>
                                            </div>
                                        </div>
                                            <CardComponent students={students} />
                                        <hr />

                                        {/*<ManagementRow items={management} />*/}
                                        {/*<StudentRow students={students} />*/}
                                    </div>
                                </div>
                            </>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
            </section>
        </>
    );
};
const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};

export default connect(mapStateToProps, null)(StudentTag);
