import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import Loader from "../common/loader";
import PageTitle from "../common/pagetitle/pagetitle";
import { showAlert } from "../common/sweetalert/sweetalert";
import { serverLink } from "../url";
import JoditEditor from "jodit-react";
import  Select from 'react-select'

const AddCourse = (props) => {
    const editorBio = React.createRef();
    const [isloading, setIsLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const [courseList, setCourseList] = useState([]);
    const [courseHeadOptions, setCourseHeadOptions] = useState([]);


    const [formData, setFormData] = useState({
        CourseName: "",
        CourseHead: "",
        Duration: "",
        DurationType: "",
        ModeOfTeaching: "",
        Overview: "",
        Status: "",
        ClassLink: "",
        EntryID: "",
        CourseHead2:"",
        InsertedBy: props.loginData[0].EmailAddress,
    })
    const getData = async () => {
        await axios.get(`${serverLink}foundation/login/get-course-list`).then((res) => {
            if (res.data.length > 0) {
                setCourseList(res.data)
            }
        })
        setIsLoading(false)

        await axios.get(`${serverLink}foundation/login/get-tutor-list`).then((res) => {
            let rows=[];
            if (res.data.length > 0) {
                res.data.map((row)=>{
                    rows.push({
                        value:row.EntryID, label: row.StaffName
                    })
                })
                setCourseHeadOptions(rows)
            }
        })
    }

    useEffect(() => {
        getData();
    }, [])

    const addCourse = async (e) => {
        e.preventDefault();
        if (formData.CourseName === "") {
            toast.error("Please enter CourseID");
            return false;
        }
        setLoading(true);
        try {
            if (formData.EntryID === "") {
                await axios.post(`${serverLink}foundation/login/add/course`, formData).then((res) => {
                    if (res.data.message === "success") {
                        setLoading(false);
                        getData();
                        Reset();
                        showAlert("success", "Record added successfully", "success");
                        document.getElementById("btn_close").click();
                    } else if (res.data.message === "exist") {
                        setLoading(false)
                        showAlert("warning", "Module code already exist", "warning")
                    } else {
                        showAlert("error", "Network error, please check your connection", "error")
                    }
                })
            } else {
                await axios.patch(`${serverLink}foundation/login/update/course`, formData).then((res) => {
                    if (res.data.message === "success") {
                        setLoading(false);
                        getData();
                        Reset();
                        showAlert("success", "Record Updated Successfully", "success");
                        document.getElementById("btn_close").click();
                    }
                })
            }
        } catch (e) {
            console.log(e)
            setLoading(false)
            showAlert("error", "Network Error, try again", "error");
        }

    }

    const onEdit = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value,
        })
    }

    const handleCourseChange = (e) => {
        setFormData({
            ...formData,
            CourseHead: e.value,
            CourseHead2: e
        })
    }

    const onBioChange = (e) => {
        setFormData({
            ...formData,
            Overview: e
        })
    }

    const Reset = () => {
        setFormData({
            ...formData,
            CourseID: "",
            ModuleCode: "",
            Description: "",
            CreditUnit: "",
            EntryID: "",
        })
    }

    const checkActive = async (e) => {
        let course_id = e.target.getAttribute('course');
        if (e.target.checked){
            await axios.get(`${serverLink}foundation/live/activate/${course_id}`).then((res) => {
                if (res.data.message === "success") {
                    getData();
                    toast.success('Class activated successfully')
                }
            })
        }else{
            await axios.get(`${serverLink}foundation/live/deactivate/${course_id}`).then((res) => {
                if (res.data.message === "success") {
                    getData();
                    toast.success('Class deactivated successfully')
                }
            })
        }

    }

    return isloading === true ?
        (<Loader />) : (
            <>
                <PageTitle title={["Administrator", "Add Course"]} />
                <section className="section">
                    <div className="row align-items-top">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="d-flex justify-content-start">
                                        <h4>Add Course</h4>
                                    </div>
                                    <div
                                        className="d-flex justify-content-end"
                                        data-kt-customer-table-toolbar="base"
                                    >
                                        <button data-bs-toggle="modal" data-bs-target="#basicModal"
                                                className="btn btn-sm btn-primary text-white"
                                                onClick={() => {
                                                    setFormData({
                                                        ...formData,
                                                        CourseName: "",
                                                        CourseHead: "",
                                                        Duration: "",
                                                        DurationType: "",
                                                        ModeOfTeaching: "",
                                                        Overview: "",
                                                        Status: "",
                                                        EntryID: "",
                                                        CourseHead2:"",
                                                    })
                                                }}
                                        >
                                            <i className="fa fa-plus" /> Add Course
                                        </button>
                                    </div>
                                </div>

                                <div className="card-body">
                                    <div className="table table-responsive-lg">
                                        <table className="table table-striped">
                                            <thead>
                                            <tr>
                                                <th scope="col">S/No</th>
                                                <th scope="col">Course Name</th>
                                                <th scope="col">Course Head</th>
                                                <th scope="col">Duration</th>
                                                <th scope="col">DurationType</th>
                                                <th scope="col">Is Live</th>
                                                <th scope="col"> Action</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                courseList.length > 0 ?
                                                    courseList.map((x, i) => {
                                                        return (
                                                            <tr key={i}>
                                                                <td>{i + 1}</td>
                                                                <td style={{ maxWidth: "450px" }}>{x.CourseName}</td>
                                                                <td>{x.CourseHeadName.toUpperCase()}</td>
                                                                <td>{x.Duration}</td>
                                                                <td>{x.DurationType}</td>
                                                                <td>
                                                                    <div className="form-check form-switch">
                                                                        <input className="form-check-input checkAll" checked={x.IsLive === 1} course={x.EntryID} type="checkbox" role="switch" onChange={checkActive} />
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <button type="button" id="btnModal" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#basicModal" onClick={() => {
                                                                        setFormData({
                                                                            ...formData,
                                                                            CourseName: x.CourseName,
                                                                            CourseHead: x.CourseHead,
                                                                            CourseHead2: { value: x.CourseHead, label: x.CourseHeadName},
                                                                            Duration: x.Duration,
                                                                            DurationType: x.DurationType,
                                                                            ModeOfTeaching: x.ModeOfTeaching,
                                                                            Overview: x.Overview,
                                                                            Status: x.Status,
                                                                            EntryID: x.EntryID
                                                                        })
                                                                    }}> Edit
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                    :
                                                    <tr>
                                                        <td colSpan={7}>No Record</td>
                                                    </tr>
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="modal fade" id="basicModal" tabIndex={-1} aria-hidden="true" style={{ display: 'none' }}>
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content modal-lg">
                            <div className="modal-header">
                                <h5 className="modal-title">Add Module</h5>
                                <button type="button" id="btn_close" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                            </div>
                            <div className="modal-body">
                                <form onSubmit={addCourse}>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <label htmlFor="CourseName" className="col-md-12 col-lg-12 col-form-label">Course Name</label>
                                                    <input type="text" className="form-control" id="CourseName" value={formData.CourseName} onChange={onEdit} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <label htmlFor="CourseHead" className="col-md-12 col-lg-12 col-form-label">Course Head</label>
                                                    <Select
                                                        id="CourseHead"
                                                        className="form-select w-100"
                                                        value={formData.CourseHead2}
                                                        onChange={handleCourseChange}
                                                        options={courseHeadOptions}
                                                        placeholder="Select Course"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <label htmlFor="Duration" className="col-md-12 col-lg-12 col-form-label">Duration</label>
                                                    <select className="form-control form-select" onChange={onEdit} id="Duration" value={formData.Duration}>
                                                        <option value={""}>-select Duration-</option>
                                                        <option value="1">1</option>
                                                        <option value="2">2</option>
                                                        <option value="3">3</option>
                                                        <option value="4">4</option>
                                                        <option value="5">5</option>
                                                        <option value="6">6</option>
                                                        <option value="7">7</option>
                                                        <option value="8">8</option>
                                                        <option value="9">9</option>
                                                        <option value="10">10</option>
                                                        <option value="11">11</option>
                                                        <option value="12">12</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <label htmlFor="DurationType" className="col-md-12 col-lg-12 col-form-label">Duration Type</label>
                                                    <select className="form-control form-select" onChange={onEdit} id="DurationType" value={formData.DurationType}>
                                                        <option value={""}>-select Duration Type-</option>
                                                        <option value="Year">Year</option>
                                                        <option value="Month">Month</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <label htmlFor="ModeOfTeaching" className="col-md-12 col-lg-12 col-form-label">Mode Of Teaching</label>
                                                    <select className="form-control form-select" onChange={onEdit} id="ModeOfTeaching" value={formData.ModeOfTeaching}>
                                                        <option value={""}>-select Mode Of Teaching-</option>
                                                        <option value="Hybrid">Hybrid</option>
                                                        <option value="Virtual">Virtual</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <label htmlFor="Status" className="col-md-12 col-lg-12 col-form-label">Status</label>
                                                    <select className="form-control form-select" onChange={onEdit} id="Status" value={formData.Status}>
                                                        <option value={""}>-select Status-</option>
                                                        <option value="1">Active</option>
                                                        <option value="0">In-active</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <label htmlFor="ClassLink" className="col-md-12 col-lg-12 col-form-label">YouTube Link</label>
                                                    <input type="text" className="form-control" id="ClassLink" value={formData.ClassLink} onChange={onEdit} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <label htmlFor="Overview" className="col-md-12 col-lg-12 col-form-label">Overview</label>
                                                    <JoditEditor
                                                        value={formData.Overview}
                                                        ref={editorBio}
                                                        tabIndex={1}
                                                        onChange={onBioChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <div className="row mb-3">
                                                <div className="col-md-12 text-center">
                                                    <button className="btn btn-lg btn-primary col-md-6" type="submit" disabled={loading ? true : false}>
                                                        {
                                                            loading === true &&
                                                            <>
                                                                <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true" />&emsp;
                                                            </>
                                                        }
                                                        {loading === true ? "Loading..." :
                                                            "Submit"}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
}
const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};

export default connect(mapStateToProps, null)(AddCourse);
