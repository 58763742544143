import React from 'react'
import Loader from "../common/loader";
import PageTitle from '../common/pagetitle/pagetitle';
import axios from 'axios';
import { useEffect } from 'react';
import { serverLink } from '../url';
import { useState } from 'react';
import ReportTable from "../common/table/report_table";
import { toast } from "react-toastify";
import { connect } from 'react-redux';

const ApprovedApplications = (props) => {
    const editorBio = React.createRef();
    const [isloading, setIsLoading] = useState(true);
    const column = ["S/N", "APP ID", "FULL Name", "Admission Type", "EMAIL ADDRESS", "Area Of Training", "Is Disabled", "Course Name", "Highest Qualification", "Training Mode"];
    const [data, setData] = useState([]);
    const [applicants, setApplicants] = useState([]);
    const [moreList, setMoreList] = useState([]);
    const [tableHeight, setTableHeight] = useState("600px");
    const [totalRecords, setTotalRecords] = useState(0);
    const [limit, setLimit] = useState(5000);

    const getData = async () => {
        await axios.get(`${serverLink}foundation/dashboard/application/approve/${limit}`).then((res) => {
            const result = res.data.data;
            if (result.length > 0) {
                setApplicants(result)
                setTotalRecords(res.data.total)
                let rows = showTable(result)
                setTableHeight("1000px");
                setData(rows);
            } else {
                toast.error("There are no approved application available");
            }
            setIsLoading(false);
        })
            .catch((err) => {
                toast.error("NETWORK ERROR");
            });
    }

    const fetchMoreData = async (oldLimit, newLimit) => {
        try {
            const response = await axios.get(`${serverLink}foundation/dashboard/application/approve/${oldLimit}/${newLimit}`);
            const result = response.data;

            if (result.length > 0) {
                setLimit(prevState => prevState + newLimit);
                setMoreList(result);
                setApplicants(prevApplicants => [...prevApplicants, ...result]);
            } else {
                toast.error("There are no approved applications available");
            }
        } catch (error) {
            console.error("Error fetching more data:", error);
            toast.error("NETWORK ERROR");
        } finally {
            setIsLoading(false);
        }
    }
    const showTable = (result) => {
        let rows = [];
         result.map((applicant, index) => {
            rows.push([
                index + 1,
                applicant.EntryID,
                applicant.FullName,
                applicant.Cohort,
                applicant.EmailAddress,
                applicant.AreaOfTraining,
                applicant.IsDisabled === 1 ? 'Yes' :'No',
                applicant.CourseName,
                applicant.HighestQualification,
                applicant.TrainingMode,
            ]);
        });
         return rows;
    }
    
    const loadMoreRecord = () => {
      if (limit < totalRecords){
          setIsLoading(true)
          const newLimit = 5000;
          fetchMoreData(limit, newLimit)
      }else {
          toast.info('No More Record')
      }
    }

    useEffect(() => {
        getData();
    }, [])

    useEffect(() => {
        setTableHeight("1000px");
        const rows = showTable([...applicants, moreList]);
        setData(rows);
    }, [JSON.stringify(applicants)])


    return isloading === true ?
        (<Loader />) : (
            <>
                <PageTitle title={["Administrator", "Approved Applications"]} />
                <ReportTable title={`Approved Applications`} columns={column} data={data} height={tableHeight}
                />
                <button
                    type="button"
                    className="btn btn-sm btn-info float-end mt-3"
                    style={{ marginBottom: "15px" }}
                    onClick={loadMoreRecord}
                >
                    Load More Record ({totalRecords})
                </button>
            </>
        )
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};

export default connect(mapStateToProps, null)(ApprovedApplications);
